import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

const schema = yup.object({
  title: yup.string().required(),
});

const Add = () => {
  const section = "allergens";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/settings/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          <Formik
            validationSchema={schema}
            onSubmit={(formData, { setSubmitting }) => {
              setErrorMessage("");

              Api.add(userData.session_hash, section, formData).then(
                (response) => {
                  console.log("Add.response", response);

                  if (response.error) {
                    setErrorMessage(response.error);
                  } else if (response.authFailed) {
                    authFailed();
                    localStorage.removeItem("user");
                    history.push("/");
                  } else {
                    history.push(`/settings/${section}/list`);
                  }

                  setSubmitting(false);
                },
                (error) => {
                  console.log("Add.error", error);

                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();

                  setErrorMessage(resMessage);
                  setSubmitting(false);
                }
              );
            }}
            initialValues={{
              title: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                    placeholder=""
                    isInvalid={!!errors.title}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={isSubmitting ? "btn--loading" : ""}
                >
                  {isSubmitting ? <div className="lds-dual-ring"></div> : null}
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default Add;
