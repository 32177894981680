import React, { useState, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

type mealTypes = {
  id: number;
  title: string;
};

type initialDataType = {
  id: number;
  title: string;
  meal_types: Array<mealTypes>;
  perday2types: Array<number>;
};

const Edit = (props: any) => {
  console.log("Edit", props);
  const section = "meals-perday";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState("");

  const initialDataState = {
    id: 0,
    title: "",
    meal_types: [],
    perday2types: [],
  };

  const [currentData, setCurrentData] = useState<initialDataType>(
    initialDataState
  );

  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, section, id)
      .then((response: any) => {
        console.log("Edit.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setCurrentData(response.data);
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  const saveData = (event: any) => {
    const form = event.currentTarget;
    console.log("saveData", event);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return false;
    }

    setValidated(true);
    setErrorMessage("");
    setIsSubmitting(true);

    Api.edit(userData.session_hash, section, currentData.id, currentData).then(
      (response) => {
        console.log("Edit.response", response);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          history.push(`/settings/${section}/list`);
        }

        setIsSubmitting(false);
      },
      (error) => {
        console.log("Edit.error", error);

        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setErrorMessage(resMessage);
        setIsSubmitting(false);
      }
    );

    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const handleChange = (e: any) => {
    let fieldName = e.target.name;
    let fieldVal = e.target.value;
    // console.log("handleChange", e);

    setCurrentData((oldData) => {
      const newData = {
        ...oldData,
        [fieldName]: fieldVal,
      };
      // console.log("handleChange", newData);

      return newData;
    });
  };

  const handleChangeMealtypes = (e: any) => {
    console.log("handleChangeMealtypes", e, e.target.checked, e.target.value);

    if (e.target.checked) {
      //append to array
      setCurrentData((oldData) => {
        if (oldData.perday2types.indexOf(parseInt(e.target.value)) === -1) {
          oldData.perday2types.push(parseInt(e.target.value));
          //console.log("handleChangeMealtypes.checked1", oldData);
        }

        const newData = {
          ...oldData,
        };
        //console.log("handleChangeMealtypes.checked2", newData);

        return newData;
      });
    } else {
      //remove from array
      setCurrentData((oldData) => {
        const index = oldData.perday2types.indexOf(parseInt(e.target.value));
        if (index !== -1) {
          oldData.perday2types.splice(index, 1);
        }

        const newData = {
          ...oldData,
        };
        //console.log("handleChangeMealtypes.unchecked", newData, index);

        return newData;
      });
    }
  };

  useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/settings/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Form noValidate validated={validated} onSubmit={saveData}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={currentData.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Meal types:</Form.Label>
                <div className="mb-3" style={{ maxWidth: "400px" }}>
                  {currentData.meal_types.map((type) => (
                    <Form.Check
                      inline
                      label={type.title}
                      type="checkbox"
                      key={`custom-inline-${type.id}`}
                      onChange={handleChangeMealtypes}
                      name="meal_types"
                      value={type.id}
                      checked={currentData.perday2types.indexOf(type.id) !== -1}
                    />
                  ))}
                </div>
              </Form.Group>

              <Button
                type="submit"
                disabled={isSubmitting}
                className={isSubmitting ? "btn--loading" : ""}
              >
                {isSubmitting ? <div className="lds-dual-ring"></div> : null}
                Submit
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Edit;
