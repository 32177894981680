import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Alert,
  Table,
  InputGroup,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faArrowCircleLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

type listGroupsAttrs = {
  values: any;
  setFieldValue: (field: any, value: any) => void;
  setModalData: (any: any) => void;
  modalToggle: () => void;
  totalGroups: any;
};

export type listGroupsTypes = {
  id: number;
  title: string;
  netto: number;
};

export const ListGroups = ({
  values,
  setFieldValue,
  setModalData,
  modalToggle,
  totalGroups,
}: listGroupsAttrs) => {
  // console.log("ListGroups", values);

  return (
    <Form.Row className="my-3">
      <Form.Group as={Col}>
        <div className="uc-hrdivider mt-4">
          <hr />
          <span>Groups</span>
        </div>
        {values.groups.length > 0 ? (
          <Table striped bordered hover className="table-middle">
            <thead>
              <tr>
                <th>Title</th>
                <th>Netto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {values.groups.map((currentGroup: any) => {
                console.log(
                  "ListGroups",
                  currentGroup,
                  values.ingredients,
                  values.semifinished
                );

                /*
                    Calc netto
                */
                {
                  currentGroup.nettoAuto = 0;

                  // Ingredients
                  if (values.ingredients.length > 0) {
                    values.ingredients.map((currentIngredient: any) => {
                      /* console.log(
                        "ListGroups",
                        currentGroup,
                        currentIngredient
                      ); */

                      // This group?
                      if (
                        currentIngredient?.group_id &&
                        currentIngredient.group_id > 0 &&
                        currentIngredient.group_id == currentGroup.id
                      ) {
                        let itemNetto = parseFloat(
                          currentIngredient.netto as any
                        );

                        // console.log("ListGroups.itemNetto", itemNetto);

                        // Got netto?
                        if (itemNetto > 0) {
                          currentGroup.nettoAuto += itemNetto;
                        }
                      }
                    });
                  }

                  // Semifinished
                  if (values.semifinished.length > 0) {
                    values.semifinished.map((currentSemifinished: any) => {
                      /* console.log(
                        "ListGroups",
                        currentGroup,
                        currentSemifinished
                      ); */

                      // This group?
                      if (
                        currentSemifinished?.group_id &&
                        currentSemifinished.group_id > 0 &&
                        currentSemifinished.group_id == currentGroup.id
                      ) {
                        let itemNetto = parseFloat(
                          currentSemifinished.netto as any
                        );

                        // console.log("ListGroups.itemNetto", itemNetto);

                        // Got netto?
                        if (itemNetto > 0) {
                          currentGroup.nettoAuto += itemNetto;
                        }
                      }
                    });
                  }
                }

                return (
                  <tr key={`table-row-group-${currentGroup.id}`}>
                    <td>
                      <Form.Control
                        type="text"
                        value={currentGroup.title}
                        onChange={(e) => {
                          let title = e.target.value;

                          console.log("onChange", values.groups, title);

                          let newGroups = values.groups;
                          const foundIndex = newGroups.findIndex(
                            (x: any) => x.id == currentGroup.id
                          );
                          newGroups[foundIndex].title = title as string;

                          setFieldValue("groups", newGroups);
                        }}
                        name={`groups-${currentGroup.id}-title`}
                        /* isInvalid={
                                    errors.groups &&
                                    errors.groups instanceof Array &&
                                    errors.groups.length >= index + 1 &&
                                    errors.groups[index] !== undefined &&
                                    "netto" in (errors.groups[index] as any)
                                    ? true
                                    : false
                                } */
                        autoComplete="off"
                      />
                    </td>
                    <td>
                      <Form.Row>
                        <Col className="col-auto">
                          <Form.Control
                            type="text"
                            style={{ maxWidth: "80px" }}
                            value={currentGroup.netto}
                            onChange={(e) => {
                              let netto = e.target.value;
                              if (netto.includes(",")) {
                                netto = netto.replace(/,/, ".");
                              }

                              let newGroups = values.groups;
                              const foundIndex = newGroups.findIndex(
                                (x: any) => x.id == currentGroup.id
                              );
                              newGroups[foundIndex].netto = netto as any;

                              console.log(
                                "groupNetto",
                                values.groups,
                                newGroups
                              );
                              setFieldValue("groups", newGroups);
                            }}
                            name={`groups-${currentGroup.id}-netto`}
                            /* isInvalid={
                            errors.groups &&
                            errors.groups instanceof Array &&
                            errors.groups.length >= index + 1 &&
                            errors.groups[index] !== undefined &&
                            "netto" in (errors.groups[index] as any)
                            ? true
                            : false
                        } */
                            autoComplete="off"
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="text"
                            style={{ maxWidth: "80px" }}
                            value={currentGroup.nettoAuto}
                            readOnly={true}
                          />
                        </Col>
                      </Form.Row>
                    </td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          setModalData({
                            title: "Confirmation",
                            message: "Do you really want to remove this Group?",
                            button: {
                              title: "Yes",
                              onClick: () => {
                                console.log("yes");

                                const newGroups = values.groups.filter(
                                  function (obj: any) {
                                    return obj.id !== currentGroup.id;
                                  }
                                );

                                console.log(
                                  "deleteGroup",
                                  values.groups,
                                  newGroups
                                );
                                setFieldValue("groups", newGroups);
                              },
                            },
                          });
                          modalToggle();
                        }}
                        className={`inline-action-item text-danger`}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <th></th>
                <th>{totalGroups.netto}</th>
                <th></th>
              </tr> */}
            </tbody>
          </Table>
        ) : null}
        <div className="mt-3">
          <Button
            variant="link"
            onClick={() => {
              let maxId = 0;
              if (values.groups.length) {
                maxId = Math.max(...values.groups.map((e: any) => e.id));
              }
              maxId++;

              let newGroups = [
                ...values.groups,
                {
                  id: maxId,
                  title: "",
                  netto: 0,
                },
              ];

              console.log("newGroup", values.groups, newGroups);
              setFieldValue("groups", newGroups);
            }}
            className={`inline-action-item text-danger`}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Add new group
          </Button>
        </div>
      </Form.Group>
    </Form.Row>
  );
};
