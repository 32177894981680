import {
  xlsxGenerateStyled,
  SheetsDetailedType,
  CellStyles,
  utilsStyleRange,
} from "utils/xlsx";

import { czWeekDay } from "utils/dates";

type IngredientsType = {
  id: number;
  title: string;
  brutto: number;
  netto: number;
  qty: number;
  group_title?: string;
};

type SemifinishedType = {
  id: number;
  title: string;
  netto: number;
  qty: number;
};

type MealInfoType = {
  [key: string]: MealType;
};

type MealType = {
  clients: Array<CustomersType>;
  ingredients: Array<IngredientsType>;
  semifinished: Array<SemifinishedType>;
  title: string;
  qty: number;
  netto: number;
  meals_types_title: string;
  meals_types_id: number;
  groups: Array<GroupType>;
};

type GroupType = {
  netto: number;
  netto_auto: number;
  title: string;
};

type CustomersType = {
  id: number;
  contacts: ContactsType;
  meals_plans_title: string;
  kitchen_info?: string;
  delka_tydne?: string;
  meals_types_names?: string;
  meals_types_names_nums?: string;
  meals_types_replaced?: MealReplacedType;
  meals_types_replaced_nums?: MealReplacedType;
  meals_types_program?: string;
  meals_types_program_nums?: string;
  meals_chody_protein?: number;
};

type MealReplacedType = {
  [key: string]: string;
};

type ContactsType = {
  name: string;
  phones: Array<string>;
  emails: Array<string>;
};

type MealDateInfoType = {
  meals: MealInfoType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_netto: number;
  meals_qty: number;
};

type MealDatesType = {
  [key: string]: MealDateInfoType;
};

type MealsDataType = {
  cooking_id: number;
  meals: MealDatesType;
  meals_bigsmall: MealDatesType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_qty: number;
  meals_types: Array<MealsTypesType>;
  clients: Array<ClientsAllType>;
  customers: Array<CustomersType>;
};

type ClientsAllType = {
  day: string;
  id: number;
  meals: Array<MealType>;
  name: string;
};

type MealsTypesType = {
  id: number;
  order_pos: number;
  title: string;
};

/**
 * Generate xlsx for the Kitchen
 */
export const prehledXLSX = (
  selectedCookDay: string,
  mealsData: MealsDataType
) => {
  const sheets = [] as SheetsDetailedType;

  // Klienti
  sheetKlienti(sheets, mealsData);

  // Klienti 2
  sheetKlienti2(sheets, mealsData);

  // Meals
  const mealsArray = [["#", "Jídlo", "Netto, gr", "Množství"]] as any;
  Object.keys(mealsData.meals).map((dayKey, index) => {
    const currentDay = mealsData.meals[dayKey];
    Object.keys(currentDay.meals).map((mealKey, index) => {
      const mealsData = currentDay.meals[mealKey] as any;
      mealsArray.push([
        mealsArray.length,
        mealsData.title,
        Math.round(mealsData.netto),
        mealsData.qty,
      ]);
    });
  });
  // Semifinished
  mealsArray.push(["", "", "", ""]);
  mealsArray.push(["#", "Polotovar", "Netto, gr", "Množství"]);
  mealsData.semifinished.map((item, index) => {
    mealsArray.push([index + 1, item.title, item.netto, item.qty]);
  });
  sheets.push({ title: "Jídla,Polotovar", data: mealsArray });

  /* // Semifinished
    const semifinishedArray = [
        ["#", "Polotovar", "Netto, gr", "Množství"],
    ] as any;
    mealsData.semifinished.map((item, index) => {
        semifinishedArray.push([
        semifinishedArray.length,
        item.title,
        item.netto,
        item.qty,
        ]);
    });
    sheets.push({ title: "Polotovar", data: semifinishedArray }); */

  // Ingredients
  {
    // Re-sort
    const sortedIngredients = [...mealsData.ingredients];
    sortedIngredients.sort((a, b) => {
      let cmp = 0;
      if (a.group_title === undefined || b.group_title === undefined) cmp = 0;
      // if (a.group_title === "") return -1;
      else if (a.group_title < b.group_title) cmp = -1;
      else if (a.group_title > b.group_title) cmp = 1;
      else cmp = 0;

      if (cmp === 0) {
        if (a.title < b.title) cmp = -1;
        else if (a.title > b.title) cmp = 1;
        else cmp = 0;
      }

      return cmp;
    });

    // console.log(sortedIngredients);

    const ingredientsArray = [
      ["#", "Skupina", "Surovina", "Brutto, gr"],
    ] as any;
    sortedIngredients.map((item, index) => {
      ingredientsArray.push([
        ingredientsArray.length,
        item.group_title,
        item.title,
        Math.round(item.brutto),
      ]);
    });
    sheets.push({ title: "Suroviny", data: ingredientsArray });
  }

  // Package
  const packageArray = [["#", "Jídlo", "Druh", "Množství", "Den"]] as any;
  Object.keys(mealsData.meals).map((dayKey, index) => {
    const currentDay = mealsData.meals[dayKey];
    Object.keys(currentDay.meals).map((mealKey, index) => {
      const mealsData = currentDay.meals[mealKey] as any;
      packageArray.push([
        packageArray.length,
        mealsData.title,
        mealsData.meals_types_title,
        mealsData.qty,
        dayKey + " (" + czWeekDay(dayKey) + ")",
      ]);
    });
  });
  sheets.push({ title: "Balení", data: packageArray });

  // Assembly
  const assemblyTitle = ["#", "Klient", "Den"];
  const assemblyArray = [assemblyTitle] as any;
  mealsData.meals_types.map((item, index) => {
    assemblyTitle.push(item.title);
  });
  mealsData.clients.map((currentClient, index) => {
    const currentClientRow = [
      assemblyArray.length,
      currentClient.name,
      currentClient.day,
    ];
    mealsData.meals_types.map((currentMealType, index) => {
      let clientMealFound = "";
      currentClient.meals.map((currentClientMeal, index) => {
        if (currentMealType.id === currentClientMeal.meals_types_id) {
          clientMealFound = currentClientMeal.title;
        }
      });
      currentClientRow.push(clientMealFound);
    });
    assemblyArray.push(currentClientRow);
  });
  sheets.push({ title: "Kompletace", data: assemblyArray });

  const dateObj = new Date(selectedCookDay);
  const dateString: String = dateObj.getDate() + "." + (dateObj.getMonth() + 1);

  // Generate XLSX
  xlsxGenerateStyled(`Výroba ${dateString} - Přehled.xlsx`, sheets);
};

/**
 * Add clients
 */
const sheetKlienti = (sheets: Array<any>, mealsData: MealsDataType) => {
  // Clients
  const clientsArray = [
    [
      "#",
      "Jméno",
      // "Program",
      "Jídla",
      "Bez sobot",
      "Neděle",
      // "Protein",
      "Info pro kuchyň",
    ],
  ] as any;
  const merges = [] as Array<string>;
  const styles = [] as CellStyles;

  // Border below title
  utilsStyleRange(
    styles,
    {
      font: { bold: true },
      border: { bottom: { style: "thin" } },
    },
    "A",
    "G", // "H",
    clientsArray.length
  );

  styles.push({
    cell: "D" + clientsArray.length,
    style: {
      font: { bold: true },
      alignment: { horizontal: "center" },
      border: { bottom: { style: "thin" }, left: { style: "thin" } },
    },
  });

  styles.push({
    cell: "E" + clientsArray.length,
    style: {
      font: { bold: true },
      alignment: { horizontal: "center" },
      border: { bottom: { style: "thin" }, left: { style: "thin" } },
    },
  });

  let mealTypesLength = 0;
  let ci = 1;
  mealsData.customers.map((item, index) => {
    let bgColor = { rgb: "FFFFFFFF" } as any;
    if (ci % 2 == 0) bgColor = { rgb: "FFD9D9D9" };
    // console.log("bgcolor: ", bgColor);
    // else bgColor = false;

    // With replacement
    if (item.meals_types_replaced !== undefined) {
      // console.log("Replaced:", item, item.meals_types_replaced);

      let di = 0;
      const replacedMealsTypes = Object.keys(item.meals_types_replaced);
      replacedMealsTypes.map((replaceDay, index) => {
        let replaceMealType = "";
        if (
          item.meals_types_replaced !== undefined &&
          item.meals_types_replaced[replaceDay] !== undefined
        ) {
          replaceMealType = item.meals_types_replaced[replaceDay];
        }

        /* console.log(
            "Replaced.replaceDay",
            replaceDay,
            replaceMealType,
            replacedMealsTypes
            ); */

        /*
            // Merges
            if (di === 0 && replacedMealsTypes.length > 1) {
            console.log(
                "Replace.Merge",
                { ...replacedMealsTypes },
                clientsArray.length,
                { ...item }
            );

            merges.push(
                "A" + clientsArray.length + ":A" + (clientsArray.length + 1)
            );
            merges.push(
                "B" + clientsArray.length + ":B" + (clientsArray.length + 1)
            );
            merges.push(
                "C" + clientsArray.length + ":C" + (clientsArray.length + 1)
            );
            merges.push(
                "E" + clientsArray.length + ":E" + (clientsArray.length + 1)
            );
            merges.push(
                "F" + clientsArray.length + ":F" + (clientsArray.length + 1)
            );
            merges.push(
                "G" + clientsArray.length + ":G" + (clientsArray.length + 1)
            );
            merges.push(
                "H" + clientsArray.length + ":H" + (clientsArray.length + 1)
            );
            }
            */

        // Multidays
        if (replacedMealsTypes.length > 1) {
          if (di === 0) {
            /* console.log(
                "Multi.Replace.Data",
                replaceDay + ": " + replaceMealType,
                di
                ); */

            clientsArray.push([
              ci,
              item.contacts.name,
              // item.meals_plans_title,
              replaceDay + ": " + replaceMealType,
              item.delka_tydne === "mon-fri" ? "Ano" : "",
              item.delka_tydne === "mon-sun" ? "Ano" : "",
              // item.meals_chody_protein ? "Ano" : "",
              item.kitchen_info,
            ]);

            if (
              (replaceDay + ": " + replaceMealType).length > mealTypesLength
            ) {
              mealTypesLength = (replaceDay + ": " + replaceMealType).length;
            }

            merges.push(
              "A" + clientsArray.length + ":A" + (clientsArray.length + 1)
            );
            merges.push(
              "B" + clientsArray.length + ":B" + (clientsArray.length + 1)
            );
            merges.push(
              "D" + clientsArray.length + ":D" + (clientsArray.length + 1)
            );
            merges.push(
              "E" + clientsArray.length + ":E" + (clientsArray.length + 1)
            );
            merges.push(
              "F" + clientsArray.length + ":F" + (clientsArray.length + 1)
            );
            merges.push(
              "G" + clientsArray.length + ":G" + (clientsArray.length + 1)
            );
            /* merges.push(
                "H" + clientsArray.length + ":H" + (clientsArray.length + 1)
                ); */

            // Style
            styles.push({
              cell: "A" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "B" + clientsArray.length,
              style: {
                font: { bold: true },
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "C" + clientsArray.length,
              style: {
                font: { bold: true },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "D" + clientsArray.length,
              style: {
                alignment: { vertical: "center", horizontal: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "E" + clientsArray.length,
              style: {
                alignment: { vertical: "center", horizontal: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "F" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "G" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            /* styles.push({
                cell: "H" + clientsArray.length,
                style: { alignment: { vertical: "center" } },
                });*/
          } else {
            console.log(
              "Multi.Replace.Empty",
              replaceDay + ": " + replaceMealType,
              di
            );

            clientsArray.push([
              "",
              "",
              // "",
              replaceDay + ": " + replaceMealType,
              "",
              "",
              // "",
              "",
            ]);

            if (
              (replaceDay + ": " + replaceMealType).length > mealTypesLength
            ) {
              mealTypesLength = (replaceDay + ": " + replaceMealType).length;
            }

            // Style
            styles.push({
              cell: "C" + clientsArray.length, // D
              style: {
                font: { bold: true },
                fill: { fgColor: bgColor },
              },
            });
          }

          // Single day
        } else {
          clientsArray.push([
            ci,
            item.contacts.name,
            // item.meals_plans_title,
            replaceDay + ": " + replaceMealType,
            item.delka_tydne === "mon-fri" ? "Ano" : "",
            item.delka_tydne === "mon-sun" ? "Ano" : "",
            // item.meals_chody_protein ? "Ano" : "",
            item.kitchen_info,
          ]);

          if ((replaceDay + ": " + replaceMealType).length > mealTypesLength) {
            mealTypesLength = (replaceDay + ": " + replaceMealType).length;
          }

          // Style
          styles.push({
            cell: "A" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "B" + clientsArray.length,
            style: {
              font: { bold: true },
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
          /* styles.push({
                cell: "C" + clientsArray.length,
                style: { alignment: { vertical: "center" } },
            }); */
          styles.push({
            cell: "C" + clientsArray.length,
            style: { font: { bold: true }, fill: { fgColor: bgColor } },
          });
          styles.push({
            cell: "D" + clientsArray.length,
            style: {
              alignment: { vertical: "center", horizontal: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "E" + clientsArray.length,
            style: {
              alignment: { vertical: "center", horizontal: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "F" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "G" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
        }

        /* styles.push({
            cell: "A" + (clientsArray.length + 1),
            style: {
                font: { bold: true },
                alignment: { vertical: "center" },
            },
            }); */

        /*
            merges.push(
            "B" + clientsArray.length + ":B" + (clientsArray.length + 1)
            );

            styles.push({
            cell: "B" + clientsArray.length,
            style: {
                font: { bold: true },
                alignment: { vertical: "center" },
            },
            });

            styles.push({
            cell: "B" + (clientsArray.length + 1),
            style: {
                font: { bold: true },
                alignment: { vertical: "center" },
            },
            });

            merges.push(
            "C" + clientsArray.length + ":C" + (clientsArray.length + 1)
            );

            styles.push({
            cell: "C" + clientsArray.length,
            style: {
                alignment: { vertical: "center" },
            },
            });
            */
        di++;
      });

      styles.push({
        cell: "A" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "B" + clientsArray.length,
        style: {
          font: { bold: true },
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      /* styles.push({
            cell: "C" + clientsArray.length,
            style: {
            border: { bottom: { style: "thin" } },
            },
        }); */
      styles.push({
        cell: "C" + clientsArray.length,
        style: {
          font: { bold: true },
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "D" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "E" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "F" + clientsArray.length,
        style: {
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "G" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });

      // No replacement
    } else {
      const currentMealsNamesList =
        item.meals_types_names !== ""
          ? item.meals_types_names
          : item.meals_types_program;

      clientsArray.push([
        ci,
        item.contacts.name,
        // item.meals_plans_title,
        currentMealsNamesList,
        item.delka_tydne === "mon-fri" ? "Ano" : "",
        item.delka_tydne === "mon-sun" ? "Ano" : "",
        // item.meals_chody_protein ? "Ano" : "",
        item.kitchen_info,
      ]);

      if (
        currentMealsNamesList !== undefined &&
        currentMealsNamesList.length > mealTypesLength
      ) {
        mealTypesLength = currentMealsNamesList.length;
      }

      utilsStyleRange(
        styles,
        {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
        "A",
        "G",
        clientsArray.length
      );

      styles.push({
        cell: "D" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "E" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "F" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
    }

    ci++;
  });

  // Get widths
  const widths = [{ wch: 3 }] as any;
  let custNameWidth = 0;
  let commentWidth = 0;
  mealsData.customers.map((item, index) => {
    if (custNameWidth < item.contacts.name.length) {
      custNameWidth = item.contacts.name.length;
    }
    if (
      item?.kitchen_info !== undefined &&
      commentWidth < item.kitchen_info.length
    ) {
      commentWidth = item.kitchen_info.length;
    }
  });
  widths.push({ wch: custNameWidth > 0 ? custNameWidth - 5 : 30 });
  widths.push({ wch: mealTypesLength > 0 ? mealTypesLength - 10 : 55 });
  widths.push({ wch: 8 });
  widths.push({ wch: 6 });
  widths.push({ wch: commentWidth > 0 ? commentWidth - 20 : 30 });
  console.log("Widths: ", widths);

  // Add to sheet
  sheets.push({
    title: "Klienti",
    data: clientsArray,
    merges: merges,
    styles: styles,
    colWidths: widths,
  });
};

/**
 * Add clients
 */
const sheetKlienti2 = (sheets: Array<any>, mealsData: MealsDataType) => {
  // Clients
  const clientsArray = [
    [
      "#",
      "Jméno",
      // "Program",
      "Jídla",
      "Bez sobot",
      "Neděle",
      // "Protein",
      "Info pro kuchyň",
    ],
  ] as any;
  const merges = [] as Array<string>;
  const styles = [] as CellStyles;

  // Border below title
  utilsStyleRange(
    styles,
    {
      font: { bold: true },
      border: { bottom: { style: "thin" } },
    },
    "A",
    "G", // "H",
    clientsArray.length
  );

  styles.push({
    cell: "D" + clientsArray.length,
    style: {
      font: { bold: true },
      alignment: { horizontal: "center" },
      border: { bottom: { style: "thin" }, left: { style: "thin" } },
    },
  });

  styles.push({
    cell: "E" + clientsArray.length,
    style: {
      font: { bold: true },
      alignment: { horizontal: "center" },
      border: { bottom: { style: "thin" }, left: { style: "thin" } },
    },
  });

  let mealTypesLength = 0;
  let ci = 1;
  mealsData.customers.map((item, index) => {
    let bgColor = { rgb: "FFFFFFFF" } as any;
    if (ci % 2 == 0) bgColor = { rgb: "FFD9D9D9" };

    // With replacement
    if (item.meals_types_replaced_nums !== undefined) {
      // console.log("Replaced:", item, item.meals_types_replaced_nums);

      let di = 0;
      const replacedMealsTypes = Object.keys(item.meals_types_replaced_nums);
      replacedMealsTypes.map((replaceDay, index) => {
        let replaceMealType = "";
        if (
          item.meals_types_replaced_nums !== undefined &&
          item.meals_types_replaced_nums[replaceDay] !== undefined
        ) {
          replaceMealType = item.meals_types_replaced_nums[replaceDay];
        }

        // Multidays
        if (replacedMealsTypes.length > 1) {
          if (di === 0) {
            /* console.log(
                  "Multi.Replace.Data",
                  replaceDay + ": " + replaceMealType,
                  di
                  ); */

            clientsArray.push([
              ci,
              item.contacts.name,
              // item.meals_plans_title,
              replaceDay + ": " + replaceMealType,
              item.delka_tydne === "mon-fri" ? "Ano" : "",
              item.delka_tydne === "mon-sun" ? "Ano" : "",
              // item.meals_chody_protein ? "Ano" : "",
              item.kitchen_info,
            ]);

            if (
              (replaceDay + ": " + replaceMealType).length > mealTypesLength
            ) {
              mealTypesLength = (replaceDay + ": " + replaceMealType).length;
            }

            merges.push(
              "A" + clientsArray.length + ":A" + (clientsArray.length + 1)
            );
            merges.push(
              "B" + clientsArray.length + ":B" + (clientsArray.length + 1)
            );
            merges.push(
              "D" + clientsArray.length + ":D" + (clientsArray.length + 1)
            );
            merges.push(
              "E" + clientsArray.length + ":E" + (clientsArray.length + 1)
            );
            merges.push(
              "F" + clientsArray.length + ":F" + (clientsArray.length + 1)
            );
            merges.push(
              "G" + clientsArray.length + ":G" + (clientsArray.length + 1)
            );
            /* merges.push(
                  "H" + clientsArray.length + ":H" + (clientsArray.length + 1)
                  ); */

            // Style
            styles.push({
              cell: "A" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "B" + clientsArray.length,
              style: {
                font: { bold: true },
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "C" + clientsArray.length,
              style: {
                font: { bold: true },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "D" + clientsArray.length,
              style: {
                alignment: { vertical: "center", horizontal: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "E" + clientsArray.length,
              style: {
                alignment: { vertical: "center", horizontal: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "F" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                border: { left: { style: "thin" } },
                fill: { fgColor: bgColor },
              },
            });
            styles.push({
              cell: "G" + clientsArray.length,
              style: {
                alignment: { vertical: "center" },
                fill: { fgColor: bgColor },
              },
            });
            /* styles.push({
                  cell: "H" + clientsArray.length,
                  style: { alignment: { vertical: "center" } },
                  });*/
          } else {
            console.log(
              "Multi.Replace.Empty",
              replaceDay + ": " + replaceMealType,
              di
            );

            clientsArray.push([
              "",
              "",
              // "",
              replaceDay + ": " + replaceMealType,
              "",
              "",
              // "",
              "",
            ]);

            if (
              (replaceDay + ": " + replaceMealType).length > mealTypesLength
            ) {
              mealTypesLength = (replaceDay + ": " + replaceMealType).length;
            }

            // Style
            styles.push({
              cell: "C" + clientsArray.length, // D
              style: {
                font: { bold: true },
                fill: { fgColor: bgColor },
              },
            });
          }

          // Single day
        } else {
          clientsArray.push([
            ci,
            item.contacts.name,
            // item.meals_plans_title,
            replaceDay + ": " + replaceMealType,
            item.delka_tydne === "mon-fri" ? "Ano" : "",
            item.delka_tydne === "mon-sun" ? "Ano" : "",
            // item.meals_chody_protein ? "Ano" : "",
            item.kitchen_info,
          ]);

          if ((replaceDay + ": " + replaceMealType).length > mealTypesLength) {
            mealTypesLength = (replaceDay + ": " + replaceMealType).length;
          }

          // Style
          styles.push({
            cell: "A" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "B" + clientsArray.length,
            style: {
              font: { bold: true },
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
          /* styles.push({
                  cell: "C" + clientsArray.length,
                  style: { alignment: { vertical: "center" } },
              }); */
          styles.push({
            cell: "C" + clientsArray.length,
            style: { font: { bold: true }, fill: { fgColor: bgColor } },
          });
          styles.push({
            cell: "D" + clientsArray.length,
            style: {
              alignment: { vertical: "center", horizontal: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "E" + clientsArray.length,
            style: {
              alignment: { vertical: "center", horizontal: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "F" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              border: { left: { style: "thin" } },
              fill: { fgColor: bgColor },
            },
          });
          styles.push({
            cell: "G" + clientsArray.length,
            style: {
              alignment: { vertical: "center" },
              fill: { fgColor: bgColor },
            },
          });
        }
        di++;
      });

      styles.push({
        cell: "A" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "B" + clientsArray.length,
        style: {
          font: { bold: true },
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      /* styles.push({
              cell: "C" + clientsArray.length,
              style: {
              border: { bottom: { style: "thin" } },
              },
          }); */
      styles.push({
        cell: "C" + clientsArray.length,
        style: {
          font: { bold: true },
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "D" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "E" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "F" + clientsArray.length,
        style: {
          border: { left: { style: "thin" }, bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "G" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });

      // No replacement
    } else {
      const currentMealsNamesList =
        item.meals_types_names_nums !== ""
          ? item.meals_types_names_nums
          : item.meals_types_program_nums;

      clientsArray.push([
        ci,
        item.contacts.name,
        // item.meals_plans_title,
        currentMealsNamesList,
        item.delka_tydne === "mon-fri" ? "Ano" : "",
        item.delka_tydne === "mon-sun" ? "Ano" : "",
        // item.meals_chody_protein ? "Ano" : "",
        item.kitchen_info,
      ]);

      if (
        currentMealsNamesList !== undefined &&
        currentMealsNamesList.length > mealTypesLength
      ) {
        mealTypesLength = currentMealsNamesList.length;
      }

      utilsStyleRange(
        styles,
        {
          border: { bottom: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
        "A",
        "G",
        clientsArray.length
      );

      styles.push({
        cell: "D" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "E" + clientsArray.length,
        style: {
          alignment: { vertical: "center", horizontal: "center" },
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
      styles.push({
        cell: "F" + clientsArray.length,
        style: {
          border: { bottom: { style: "thin" }, left: { style: "thin" } },
          fill: { fgColor: bgColor },
        },
      });
    }

    ci++;
  });

  // Get widths
  const widths = [{ wch: 3 }] as any;
  let custNameWidth = 0;
  let commentWidth = 0;
  mealsData.customers.map((item, index) => {
    if (custNameWidth < item.contacts.name.length) {
      custNameWidth = item.contacts.name.length;
    }
    if (
      item?.kitchen_info !== undefined &&
      commentWidth < item.kitchen_info.length
    ) {
      commentWidth = item.kitchen_info.length;
    }
  });
  widths.push({ wch: custNameWidth > 0 ? custNameWidth - 5 : 30 });
  widths.push({ wch: mealTypesLength > 0 ? mealTypesLength - 5 : 55 });
  widths.push({ wch: 8 });
  widths.push({ wch: 6 });
  widths.push({ wch: commentWidth > 0 ? commentWidth - 20 : 30 });
  console.log("Widths: ", widths);

  // Add to sheet
  sheets.push({
    title: "Klienti 2",
    data: clientsArray,
    merges: merges,
    styles: styles,
    colWidths: widths,
  });
};
