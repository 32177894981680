import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Alert, Form } from "react-bootstrap";

/**
 * Lib:
 * https://github.com/turrione/react-bootstrap-pagination
 */
import Pagination from "react-bootstrap-4-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "contexts/UserContext";
import Api from "services/api";

type listType = {
  id: number;
  title: string;
};

const List = () => {
  const section = "meals-perday";

  let history = useHistory();
  const { userData, authFailed } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState<listType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [search, setSearch] = useState<string>(""),
    onSearchInput = (e: any) => {
      const searchTitle = e?.target?.value ? e.target.value : "";
      setSearch(searchTitle);
    },
    onSearchSubmit = (e: any) => {
      e.preventDefault();
      retrieveList(1);
    };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isLoading) retrieveList(1);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const [paginationConfig, setPaginationConfig] = React.useState({
    totalPages: 1,
    currentPage: 1,
    showMax: 20,
    center: false,
    className: "mt-3 uc-pagination-wrapper",
    // size: "lg",
    threeDots: true,
    prevNext: true,
    onClick: function (page: number) {
      console.log("paginationConfig.onClick", page);

      setIsLoading(true);
      retrieveList(page);
    },
  });

  // Load once on init
  useEffect(() => {
    retrieveList(paginationConfig.currentPage);
  }, []);

  const retrieveList = async (page: number) => {
    const response = await Api.getAll(
      userData.session_hash,
      `${section}/list/?page=${page}&per_page=${paginationConfig.showMax}&search=${search}`
    );
    setIsLoading(false);

    console.log("retrieveList", page, response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      console.log("retrieveList.set", response.data);
      setErrorMessage("");
      setList(response.data.entries);

      setPaginationConfig((prevState) => {
        const newPagination = {
          ...prevState,
          ...{
            totalPages: response.data.pages,
            currentPage: page,
          },
        };

        console.log("setPaginationConfig", newPagination);

        return newPagination;
      });
    }
  };

  return (
    <>
      <Row>
        <Col className="mb-2">
          <h3>
            <Link to={`/settings/${section}/add`}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="list">
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <>
              <Form onSubmit={onSearchSubmit}>
                <Form.Row className="mb-3">
                  <Col xs="auto">
                    <Form.Control
                      placeholder="Search"
                      onChange={onSearchInput}
                      value={search}
                    />
                  </Col>
                </Form.Row>
              </Form>
              {list && list.length ? (
                <>
                  <ul className="list-group">
                    {list.map((item, index) => (
                      <li className={"list-group-item"} key={index}>
                        {item.title}

                        <Link
                          to={`/settings/${section}/edit/${item.id}`}
                          className={`inline-action-item pl-5 float-right`}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Link>
                      </li>
                    ))}
                  </ul>

                  {paginationConfig.totalPages > 1 ? (
                    <Pagination {...paginationConfig} />
                  ) : null}
                </>
              ) : (
                "No entries"
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default List;
