import {
  xlsxGenerate,
  xlsxGenerateStyled,
  SheetsType,
  SheetsDetailedType,
  CellStyles,
  utilsStyleRange,
} from "utils/xlsx";

import { wordWrap } from "utils/strings";

type SemifinishedType = {
  id: number;
  title: string;
  netto: number;
  qty: number;
};

type IngredientsType = {
  id: number;
  title: string;
  brutto: number;
  netto: number;
  qty: number;
  group_title?: string;
};

type MealType = {
  // clients: Array<CustomersType>;
  ingredients: Array<IngredientsType>;
  semifinished: Array<SemifinishedType>;
  title: string;
  qty: number;
  netto: number;
  // meals_types_title: string;
  // meals_types_id: number;
  // groups: Array<GroupType>;
};

type MealInfoType = {
  [key: string]: MealType;
};

type MealDateInfoType = {
  meals: MealInfoType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_netto: number;
  meals_qty: number;
};

type MealDatesType = {
  [key: string]: MealDateInfoType;
};

/**
 * Generate xlsx with Tech cards Appendums for the Kitchen
 */
export const techCardsAddendumXLSX = (
  selectedCookDay: string,
  mealsData: MealDatesType
) => {
  const sheets = [] as SheetsDetailedType;
  const usedSheetTitles = [] as Array<string>;

  // Days
  Object.keys(mealsData).map((dayKey, index) => {
    const currentDay = mealsData[dayKey];

    // Meals
    Object.keys(currentDay.meals).map((mealKey, index) => {
      const currentMeal = currentDay.meals[mealKey] as any;
      const merges = [] as Array<string>;
      const styles = [] as CellStyles;

      // Meal Title
      const mealDataArray = [];
      mealDataArray.push([currentMeal.title_big]);
      styles.push({
        cell: "A" + mealDataArray.length,
        style: { font: { sz: 17, bold: true } },
      });
      merges.push(
        "A" + mealDataArray.length + ":J" + mealDataArray.length + ""
      );
      mealDataArray.push(["+ " + currentMeal.title_small]);
      styles.push({
        cell: "A" + mealDataArray.length,
        style: { font: { sz: 17, bold: true } },
      });
      merges.push(
        "A" + mealDataArray.length + ":J" + mealDataArray.length + ""
      );

      // Meal Recipe
      const currentMealRecipe = currentMeal.recipe.trim();
      if (currentMealRecipe !== "") {
        const currentMealRecipeLines = currentMealRecipe.split("\n");
        currentMealRecipeLines.map((currentRecipeLine: string) => {
          const longLines = wordWrap(currentRecipeLine, 90);
          longLines.map((currentLongLine: string) => {
            mealDataArray.push([currentLongLine.trim()]);
            merges.push(
              "A" + mealDataArray.length + ":J" + mealDataArray.length
            );
          });
        });
      }
      mealDataArray.push([""]);

      // Totals
      {
        mealDataArray.push([
          "Brutto, celkem",
          "",
          "",
          "Netto, celkem",
          "",
          "",
          "Celková váha",
          "",
          "",
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
        merges.push("G" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            font: { bold: true },
            border: { top: { style: "thin" } },
          },
          "A",
          "I",
          mealDataArray.length
        );
        mealDataArray.push([
          Math.round(currentMeal.brutto),
          "",
          "",
          Math.round(currentMeal.netto),
          "",
          "",
          Math.round(currentMeal.amount),
          "",
          "",
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
        merges.push("G" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            alignment: { horizontal: "left" },
            border: { bottom: { style: "thin" } },
          },
          "A",
          "I",
          mealDataArray.length
        );
      }

      mealDataArray.push([""]);

      // Ingredients
      {
        // Title
        mealDataArray.push(["Suroviny"]);
        merges.push("A" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            font: { sz: 13, bold: true },
            border: { bottom: { style: "thin" } },
          },
          "A",
          "I",
          mealDataArray.length
        );

        // Summary
        {
          // Calc summaries
          let ingredientSumBrutto = 0;
          let ingredientSumNetto = 0;
          currentMeal.ingredients.map((item: any, index: any) => {
            ingredientSumBrutto += item.brutto;
            ingredientSumNetto += item.netto;
          });

          mealDataArray.push([
            "Brutto, celkem",
            "",
            "",
            "Netto, celkem",
            "",
            "",
          ]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
            },
            "A",
            "I",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          mealDataArray.push([
            Math.round(ingredientSumBrutto),
            "",
            "",
            Math.round(ingredientSumNetto),
            "",
            "",
            "",
            "",
            "",
          ]);
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "left" },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "I",
            mealDataArray.length
          );
        }

        // Header
        mealDataArray.push([
          "#",
          "Surovina",
          "",
          "",
          "",
          "Celk brut",
          "Celk net",
          "",
          "",
        ]);
        merges.push("B" + mealDataArray.length + ":E" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            alignment: { horizontal: "right" },
            border: { right: { style: "thin" } },
          },
          "F",
          "G",
          mealDataArray.length
        );

        // List of ingredients
        currentMeal.ingredients.map((item: any, index: any) => {
          mealDataArray.push([
            index + 1,
            item.title,
            "",
            "",
            "",
            Math.round(item.brutto * 100) / 100,
            Math.round(item.netto * 100) / 100,
          ]);
          merges.push("B" + mealDataArray.length + ":E" + mealDataArray.length);
          styles.push({
            cell: "A" + mealDataArray.length,
            style: { alignment: { horizontal: "left" } },
          });
          utilsStyleRange(
            styles,
            {
              border: { right: { style: "thin" } },
            },
            "F",
            "G",
            mealDataArray.length
          );
        });
        mealDataArray.push([""]);
      }

      // Semifinished
      if (currentMeal.semifinished.length > 0) {
        currentMeal.semifinished.map((currentSemifinished: any, index: any) => {
          mealDataArray.push([
            "Polotovar #" + (index + 1) + ": " + currentSemifinished.title,
          ]);
          utilsStyleRange(
            styles,
            {
              font: { sz: 13, bold: true },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "I",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":I" + mealDataArray.length);
          mealDataArray.push([
            "Brutto, celkem",
            "",
            "",
            "Netto, celkem",
            "",
            "",
          ]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
            },
            "A",
            "I",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          mealDataArray.push([
            Math.round(currentSemifinished.brutto),
            "",
            "",
            Math.round(currentSemifinished.netto),
            "",
            "",
          ]);
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "left" },
            },
            "A",
            "I",
            mealDataArray.length
          );

          // Semifinished ingredients
          mealDataArray.push(["Suroviny"]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "I",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":I" + mealDataArray.length);
          mealDataArray.push([
            "#",
            "Surovina",
            "",
            "",
            "",
            "Celk brut",
            "Celk net",
          ]);
          merges.push("B" + mealDataArray.length + ":E" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "right" },
              border: { right: { style: "thin" } },
            },
            "F",
            "G",
            mealDataArray.length
          );
          Object.keys(currentSemifinished.ingredients).map(
            (semifinishedIngredientKey, index) => {
              const semifinishedIngredient = currentSemifinished.ingredients[
                semifinishedIngredientKey
              ] as any;
              mealDataArray.push([
                index + 1,
                semifinishedIngredient.title,
                "",
                "",
                "",
                Math.round(semifinishedIngredient.brutto * 100) / 100,
                Math.round(semifinishedIngredient.netto * 100) / 100,
              ]);
              merges.push(
                "B" + mealDataArray.length + ":E" + mealDataArray.length
              );
              styles.push({
                cell: "A" + mealDataArray.length,
                style: { alignment: { horizontal: "left" } },
              });
              utilsStyleRange(
                styles,
                {
                  border: { right: { style: "thin" } },
                },
                "F",
                "G",
                mealDataArray.length
              );
            }
          );
          mealDataArray.push([""]);
        });
        mealDataArray.push([""]);
      }

      let retry = false;
      let attempt = 0;
      let currentSheetTitle = currentMeal.title_big.substring(0, 31);
      do {
        if (usedSheetTitles.includes(currentSheetTitle)) {
          retry = true;
          attempt++;
          currentSheetTitle =
            currentSheetTitle.substring(0, 27) + " - " + attempt;
        } else {
          usedSheetTitles.push(currentSheetTitle);
          retry = false;
          attempt = 0;
        }
      } while (retry);

      sheets.push({
        title: currentSheetTitle,
        data: mealDataArray,
        styles: styles,
        merges: merges,
      });
    });
  });

  const dateObj = new Date(selectedCookDay);
  const dateString: String = dateObj.getDate() + "." + (dateObj.getMonth() + 1);

  xlsxGenerateStyled(
    `Výroba ${dateString} - Technické karty (dodatek).xlsx`,
    sheets
  );
};
