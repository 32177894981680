import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Alert,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const schema = yup.object({
  title: yup.string().required(),
});

type daysType = string;

type mealsTypesType = {
  id: number;
  title: string;
};

type mealsType = {
  id: number;
  title: string;
  kcal?: number;
  complexity_cooking?: number;
  complexity_packaging?: number;
};

type mealTypesConnType = {
  [key: string]: mealsType;
};

/* type mealsSelectedItemType = {
  [key: string]: number;
};

type mealsSelectedType = {
  [key: string]: mealsSelectedItemType;
}; */

type initialDataType = {
  id: number;
  title: string;
  status: number;
  days_available: Array<daysType>;
  meals_types_available: Array<mealsTypesType>;
  meals_available: Array<mealsType>;
  meals: mealTypesConnType;
  // meals_selector: mealTypesConnType;
};

type kcalSummaryType = {
  [key: string]: number;
};

type complexityCookingSummaryType = {
  [key: string]: number;
};

type complexityPackagingSummaryType = {
  [key: string]: number;
};

const Edit = (props: any) => {
  console.log("Edit", props);
  const section = "weeks";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [kcalSummary, setKcalSummary] = useState<kcalSummaryType>({});
  const [complexityCookingSummary, setComplexityCookingSummary] =
    useState<complexityCookingSummaryType>({});
  const [complexityPackagingSummary, setComplexityPackagingSummary] =
    useState<complexityPackagingSummaryType>({});

  const initialDataState = {
    id: 0,
    title: "",
    status: 0,
    days_available: [],
    meals_types_available: [],
    meals_available: [],
    meals: {},
    // meals_selector: {},
  };

  const [initialData, setInitialData] =
    useState<initialDataType>(initialDataState);

  const recalcSummaries = (mealsList: any) => {
    // console.log("recalcSummaries", mealsList);

    let kcalSummaries = {} as any;
    let complexityCookingSummary = {} as any;
    let complexityPackagingSummary = {} as any;
    Object.keys(mealsList).map((tempKey) => {
      // console.log(tempKey, mealsList[tempKey], mealsList[tempKey].kcal);
      var keyParts = tempKey.split("-");

      // Kcal
      if (kcalSummaries[keyParts[1]] === undefined) {
        kcalSummaries[keyParts[1]] = 0;
      }
      kcalSummaries[keyParts[1]] += parseFloat(mealsList[tempKey].kcal);

      // Complexity cooking
      if (complexityCookingSummary[keyParts[1]] === undefined) {
        complexityCookingSummary[keyParts[1]] = 0;
      }
      complexityCookingSummary[keyParts[1]] += parseInt(
        mealsList[tempKey].complexity_cooking
      );

      // Complexity packaging
      if (complexityPackagingSummary[keyParts[1]] === undefined) {
        complexityPackagingSummary[keyParts[1]] = 0;
      }
      complexityPackagingSummary[keyParts[1]] += parseInt(
        mealsList[tempKey].complexity_packaging
      );
    });

    setKcalSummary(kcalSummaries);
    setComplexityCookingSummary(complexityCookingSummary);
    setComplexityPackagingSummary(complexityPackagingSummary);
  };

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, `menu-${section}`, id)
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });

          recalcSummaries(response.data.meals);
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/menu/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                console.log("onSubmit", formData, initialData);

                Api.edit(
                  userData.session_hash,
                  "menu-" + section,
                  formData.id,
                  formData
                ).then(
                  (response) => {
                    console.log("Edit.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                      setSubmitting(false);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      history.push(`/menu/${section}/list`);
                    }
                  },
                  (error) => {
                    console.log("Edit.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/*console.log("errors", errors)*/}
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          name="title"
                          isInvalid={!!errors.title}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option
                            value="0"
                            className="text-warning font-weight-bold"
                          >
                            Inactive
                          </option>
                          <option
                            value="1"
                            className="text-success font-weight-bold"
                          >
                            Active
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Meals */}
                  <Row>
                    <Col>
                      <Table className="uc-menus-weeks" striped bordered hover>
                        <thead>
                          <tr>
                            <th></th>
                            {values.days_available.map((day, dindex) => (
                              <th
                                className="text-center"
                                key={`th-day-${dindex}`}
                              >
                                {day}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {values.meals_types_available.map((meal, mindex) => (
                            <tr key={`tr-meal-${mindex}`}>
                              <td className="font-weight-bold text-nowrap">
                                {meal.title}
                              </td>
                              {values.days_available.map((day, dindex) => {
                                let mealKcal = 0;
                                let mealComplexityCooking = 0;
                                let mealComplexityPackaging = 0;
                                let selectedValues = [];
                                if (
                                  values?.meals[meal.id + "-" + day] !==
                                  undefined
                                ) {
                                  selectedValues.push(
                                    values.meals[meal.id + "-" + day]
                                  );
                                  mealKcal =
                                    values.meals[meal.id + "-" + day]?.kcal ||
                                    0;
                                  mealComplexityCooking =
                                    values.meals[meal.id + "-" + day]
                                      ?.complexity_cooking || 0;
                                  mealComplexityPackaging =
                                    values.meals[meal.id + "-" + day]
                                      ?.complexity_packaging || 0;
                                }
                                /* console.log(
                                  "day",
                                  meal.id,
                                  day,
                                  values.meals_selector[meal.id + "-" + day],
                                  selectedValues
                                ); */

                                return (
                                  <td key={`td-day-${dindex}`} className="pt-2">
                                    <Typeahead
                                      id={`meals-selector-${mindex}-${dindex}`}
                                      /* style={{ color: "red !important" }} */
                                      multiple={false}
                                      allowNew={false}
                                      onBlur={(e: any) => {
                                        const mealSelected =
                                          values.meals[meal.id + "-" + day];

                                        /* console.log(
                                          "Typeahead.onBlur",
                                          mealSelected,
                                          e
                                        ); */

                                        if (
                                          mealSelected === undefined ||
                                          mealSelected.id === 0
                                        ) {
                                          let newMeals = {
                                            ...values.meals,
                                          };
                                          delete newMeals[meal.id + "-" + day];

                                          setFieldValue("meals", newMeals);
                                          recalcSummaries(newMeals);
                                        }

                                        /* mealConn[meal.id + "-" + day] = {
                                          id: 0,
                                          title: text,
                                        }; */
                                      }}
                                      onChange={(selected: any) => {
                                        const selectedItem =
                                          selected.length > 0
                                            ? { ...selected[0] }
                                            : {};
                                        const mealTitle =
                                          selected.length > 0
                                            ? selectedItem.title
                                            : "";
                                        const mealId =
                                          selected.length > 0
                                            ? selectedItem.id
                                            : 0;

                                        /*
                                        Add new meal to the list
                                      */
                                        if (mealId > 0) {
                                          let mealConn =
                                            {} as mealTypesConnType;
                                          mealConn[meal.id + "-" + day] =
                                            selectedItem;
                                          let newMeals = {
                                            ...values.meals,
                                            ...mealConn,
                                          };

                                          /* // Remove duplicated
                                        newIngredients = newIngredients.filter(
                                          (thing, index, self) =>
                                            index ===
                                            self.findIndex(
                                              (t) => t.id === thing.id
                                            )
                                        ); */

                                          console.log(
                                            "meals-selector.onChange",
                                            mealConn,
                                            newMeals,
                                            values.meals
                                          );
                                          setFieldValue("meals", newMeals);
                                          recalcSummaries(newMeals);
                                          // setFieldValue("meals", newMeals);

                                          /* let mealTitleConn = {} as mealTitlesConnType;
                                        mealTitleConn[
                                          meal.id + "-" + day
                                        ] = mealTitle;
                                        let newMealsTitles = {
                                          ...values.meals_selector,
                                          mealTitleConn,
                                        };

                                        setFieldValue(
                                          "meals_selector",
                                          newMealsTitles
                                        ); */
                                        }
                                      }}
                                      onInputChange={(
                                        text: any,
                                        event: any
                                      ) => {
                                        let mealConn = {} as mealTypesConnType;
                                        mealConn[meal.id + "-" + day] = {
                                          id: 0,
                                          title: text,
                                        };
                                        let newMeals = {
                                          ...values.meals,
                                          ...mealConn,
                                        };
                                        /* console.log(
                                          "onInputChange",
                                          text,
                                          newMeals
                                        ); */

                                        setFieldValue("meals", newMeals);
                                        // recalcSummaries(day, newMeals);
                                      }}
                                      /* newSelectionPrefix="New ingredient: " */
                                      /* onBlur={(e: any) => setFieldTouched("title", true)} */
                                      labelKey="title"
                                      options={values.meals_available}
                                      placeholder="Type meal to add.."
                                      selected={selectedValues}
                                    />
                                    <div className="mt-1 mb-1">
                                      <small
                                        className={
                                          Math.round(mealKcal) <= 0
                                            ? "text-danger"
                                            : ""
                                        }
                                      >
                                        k:&nbsp;
                                        <strong>{Math.round(mealKcal)}</strong>
                                      </small>
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={tooltipComplexityCooking}
                                      >
                                        <small className="pl-2">
                                          г:&nbsp;
                                          <strong>
                                            {mealComplexityCooking}
                                          </strong>
                                        </small>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={tooltipComplexityPackaging}
                                      >
                                        <small className="pl-2">
                                          у:&nbsp;
                                          <strong>
                                            {mealComplexityPackaging}
                                          </strong>
                                        </small>
                                      </OverlayTrigger>
                                    </div>
                                    {selectedValues[0] !== undefined &&
                                    selectedValues[0].id !== undefined ? (
                                      <Link
                                        to={`/meals/all/edit/${selectedValues[0].id}`}
                                        className="meal-item-link"
                                        style={{
                                          position: "absolute",
                                          bottom: "1px",
                                          right: "2px",
                                          backgroundColor:
                                            "rgba(255,255,255,0.5)",
                                          borderRadius: "10px",
                                          padding: "2px 5px",
                                        }}
                                        href="#"
                                      >
                                        <FontAwesomeIcon icon={faUtensils} />
                                      </Link>
                                    ) : null}
                                    {/*console.log(
                                      "selectedValues",
                                      selectedValues
                                    )*/}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            {values.days_available.map((day, dindex) => {
                              return (
                                <td key={`td-day-footer-${dindex}`}>
                                  <h6 className="mt-1 mb-3 font-weight-normal">
                                    k:&nbsp;
                                    <strong>
                                      {kcalSummary[day] !== undefined
                                        ? Math.round(kcalSummary[day])
                                        : "0"}
                                    </strong>
                                    &nbsp; г:&nbsp;
                                    <strong>
                                      {complexityCookingSummary[day] !==
                                      undefined
                                        ? complexityCookingSummary[day]
                                        : "0"}
                                    </strong>
                                    &nbsp; у:&nbsp;
                                    <strong>
                                      {complexityPackagingSummary[day] !==
                                      undefined
                                        ? complexityPackagingSummary[day]
                                        : "0"}
                                    </strong>
                                  </h6>
                                </td>
                              );
                            })}
                          </tr>
                        </tfoot>
                      </Table>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-5 pb-5">
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        className={isSubmitting ? "btn--loading" : ""}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? (
                          <div className="lds-dual-ring"></div>
                        ) : null}
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

const tooltipComplexityCooking = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Сложность, готовка
  </Tooltip>
);

const tooltipComplexityPackaging = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Сложность, упаковка
  </Tooltip>
);

export default Edit;
