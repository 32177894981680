export const czWeekDay = (date: string) => {
  var czDays = [
    "Neděle",
    "Pondělí",
    "Úterý",
    "Středa",
    "Čtvrtek",
    "Pátek",
    "Sobota",
  ];

  var dateObj = new Date(date);

  return czDays[dateObj.getDay()];
};
