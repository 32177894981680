import { Route, Switch } from "react-router-dom";

import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

const Index = () => {
  const section = "packages-types";

  return (
    <Switch>
      <Route exact path={`/settings/${section}/list`} component={List} />
      <Route exact path={`/settings/${section}/add`} component={Add} />
      <Route exact path={`/settings/${section}/edit/:id`} component={Edit} />
    </Switch>
  );
};

export default Index;
