import React from "react";
import { Accordion, Nav } from "react-bootstrap";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
// import classNames from "classnames";

type SubmenuItemType = {
  title: string;
  path: string;
};

type SubmenuProps = {
  icon: IconDefinition;
  title: string;
  path: string;
  items: Array<SubmenuItemType>;
};

const SubMenu = ({ icon, title, path, items }: SubmenuProps) => {
  let location = useLocation();
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

  const toggleNavbar = () => {
    setIsCollapsed((oldState) => !oldState);
  };

  React.useEffect(() => {
    console.log("SubMenu.location", location.pathname);
  }, [location]);

  return (
    <Nav.Item className={location.pathname.startsWith(path) ? " active" : ""}>
      <Accordion>
        <Accordion.Toggle
          as={Nav.Link}
          variant="link"
          eventKey="0"
          onClick={toggleNavbar}
        >
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {title}
          <FontAwesomeIcon
            icon={isCollapsed ? faCaretDown : faCaretUp}
            className="float-right"
          />
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <nav className="nav flex-column">
            {items.map((item) => (
              /* className={ .. ${ item === "Active" ? "active" : "" } */
              <Link
                key={item.path}
                to={item.path}
                className={
                  `nav-link nav-item pl-5` +
                  (location.pathname === item.path ? " active" : "")
                }
              >
                {item.title}
              </Link>
              /* <a
                className={`nav-link nav-item pl-5`}
                href={item.path}
                key={item.path}
              >
                {item.title}
              </a> */
            ))}
          </nav>
        </Accordion.Collapse>
      </Accordion>
    </Nav.Item>
  );

  // return (
  //   <div>
  //     <NavItem
  //       onClick={this.toggleNavbar}
  //       className={classNames({ "menu-open": !this.state.collapsed })}
  //     >
  //       <NavLink className="dropdown-toggle" href="#">
  //         <FontAwesomeIcon icon={icon} className="mr-2" />
  //         {title}
  //       </NavLink>
  //     </NavItem>
  //     <Collapse
  //       isOpen={!this.state.collapsed}
  //       navbar
  //       className={classNames("items-menu", {
  //         "mb-1": !this.state.collapsed
  //       })}
  //     >
  //       {items.map(item => (
  //         <NavItem key={item} className="pl-4">
  //           <NavLink>{item}</NavLink>
  //         </NavItem>
  //       ))}
  //     </Collapse>
  //   </div>
  // );
};

export default SubMenu;
