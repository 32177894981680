/*
export const wordWrap = (
  str: string,
  width: number,
  brk: string,
  cut: boolean
): Array<string> => {
  brk = brk || "|";
  width = width || 75;
  cut = cut || false;

  if (!str) {
    return [str];
  }

  var regex =
    ".{1," + width + "}(s|$)" + (cut ? "|.{" + width + "}|.+$" : "|S+?(s|$)");

  var result = "";

  if (str) {
    var matches = str.match(RegExp(regex, "g"));
    if (matches) result = matches.join(brk);
  }

  return result.split(brk);
};
*/

export const wordWrap = (string: string, maxLineLen: number): Array<string> => {
  const words = string.trim().split(" ");
  const result = [] as Array<string>;

  let sentence = "";
  words.map((currentWord) => {
    if (
      (sentence + (sentence !== "" ? " " : "") + currentWord).length >=
      maxLineLen
    ) {
      result.push(sentence);
      if (currentWord.trim() !== "") sentence = currentWord;
    } else {
      sentence += (sentence !== "" ? " " : "") + currentWord;
    }
  });

  if (sentence !== "") result.push(sentence);

  return result;
};
