import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { UserContext } from "contexts/UserContext";
import Api from "services/api";

type entryType = {
  date?: string;
  value?: number;
};

type statsType = {
  delivered?: Array<entryType>;
  debt?: Array<entryType>;
};

type dataType = {
  daily?: statsType;
  weekly?: statsType;
};

const Finances = () => {
  let history = useHistory();

  const { userData, authFailed } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<dataType>({});
  const [graphsWidth, setGraphsWidth] = useState<number>(700);

  const graphsWrapperRef = React.useRef<any>(null);

  // Load once on init
  useEffect(() => {
    retrieveData();
  }, []);

  // Width
  useLayoutEffect(() => {
    console.log(
      "width.useEffect",
      graphsWrapperRef,
      graphsWrapperRef?.current,
      graphsWrapperRef.current?.offsetWidth
    );
    if (
      graphsWrapperRef &&
      graphsWrapperRef !== null &&
      graphsWrapperRef.current &&
      graphsWrapperRef.current !== null &&
      "offsetWidth" in graphsWrapperRef.current &&
      graphsWrapperRef.current?.offsetWidth !== undefined &&
      graphsWrapperRef.current?.offsetWidth &&
      graphsWrapperRef.current?.offsetWidth !== null
    ) {
      let newWidth = graphsWrapperRef.current.offsetWidth - 50;
      if (newWidth > 1024) newWidth = 1024;
      console.log("width.setGraphsWidth", newWidth);
      setGraphsWidth(newWidth);
    }
  }, [graphsWrapperRef, graphsWrapperRef.current]);

  const retrieveData = async () => {
    const response = await Api.getAll(
      userData.session_hash,
      `stats/meals-finances/`
    );
    setIsLoading(false);

    console.log("retrieveData", response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      console.log("retrieveData.set", response.data);
      setErrorMessage("");
      setData(response.data);
    }
  };

  // Error?
  if (errorMessage !== "") {
    return (
      <Row className="list">
        <Col md="auto">
          <Alert variant="danger">{errorMessage}</Alert>
        </Col>
      </Row>
    );
  }

  // Loading?
  if (isLoading) {
    return (
      <Row className="list">
        <Col md="auto">
          <div className="lds-dual-ring"></div>
        </Col>
      </Row>
    );
  }

  // Return graphs
  return (
    <>
      <div ref={graphsWrapperRef}>
        <Row className="list">
          <Col md="auto">
            <div>
              <h5 className="mb-4">Delivered, cook days</h5>
              {data?.daily?.delivered !== undefined ? (
                <LineChart
                  width={graphsWidth}
                  height={300}
                  data={data.daily.delivered}
                  margin={{
                    top: 0,
                    right: 50,
                    left: 50,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    dataKey="date"
                    tickFormatter={(v: any) => {
                      return "W" + v;
                    }}
                  />
                  <YAxis
                    tickFormatter={(v: any) => {
                      return v + "Kč";
                    }}
                  />
                  <Tooltip
                    formatter={(v: any, i: any, t: any) => {
                      return (
                        <>
                          {v}Kč
                          <br />
                          clients: {t.payload.clients}
                          <br />
                          meals: {t.payload.meals}
                          <br />
                          <b>{t.payload.is_prediction ? "prediction" : ""}</b>
                        </>
                      );
                    }}
                  />
                  <Line
                    dataKey="value"
                    fill="#96C5F7"
                    dot={<CustomizedDot />}
                  />
                </LineChart>
              ) : null}
            </div>

            <div className="mt-5">
              <h5 className="mb-4">Delivered, weeks</h5>
              {data?.weekly?.delivered !== undefined ? (
                <LineChart
                  width={graphsWidth}
                  height={300}
                  data={data.weekly.delivered}
                  margin={{
                    top: 0,
                    right: 50,
                    left: 50,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    dataKey="date"
                    tickFormatter={(v: any) => {
                      return "W" + v;
                    }}
                  />
                  <YAxis
                    tickFormatter={(v: any) => {
                      return v + "Kč";
                    }}
                  />
                  <Tooltip
                    formatter={(v: any, i: any, t: any) => {
                      return (
                        <>
                          {v}Kč
                          <br />
                          clients: {t.payload.clients}
                          <br />
                          meals: {t.payload.meals}
                          <br />
                          <b>{t.payload.is_prediction ? "prediction" : ""}</b>
                        </>
                      );
                    }}
                  />
                  <Line
                    dataKey="value"
                    fill="#96C5F7"
                    dot={<CustomizedDot />}
                  />
                </LineChart>
              ) : null}
            </div>

            <div className="mt-5 mb-5">
              <h5 className="mb-4">Debt</h5>
              {data?.daily?.debt !== undefined ? (
                <LineChart
                  width={graphsWidth}
                  height={300}
                  data={data.daily.debt}
                  margin={{
                    top: 0,
                    right: 50,
                    left: 50,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    dataKey="date"
                    tickFormatter={(v: any) => {
                      return "W" + v;
                    }}
                  />
                  <YAxis
                    tickFormatter={(v: any) => {
                      return v + "Kč";
                    }}
                  />
                  <Tooltip
                    formatter={(v: any, i: any, t: any) => {
                      return (
                        <>
                          {v}Kč
                          <br />
                          clients: {t.payload.clients}
                          <br />
                          meals: {t.payload.meals}
                        </>
                      );
                    }}
                  />
                  <Line
                    dataKey="value"
                    fill="#96C5F7"
                    dot={<CustomizedDot />}
                  />
                </LineChart>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

/*
const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => {
  // console.log("CustomizedAxisTick", x, y, stroke, payload);
  const date = new Date(payload.value);
  const short = date.getDate() + "/" + (date.getMonth() + 1);
  const weekDayNum = date.getDay();
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize="11px" x={0} y={5} fill="#666">
        <tspan textAnchor="middle" x="0" dy="5">
          {short}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="12">
          {weekDay[weekDayNum]}
        </tspan>
      </text>
    </g>
  );
};
*/

interface ICDotProps {
  cx?: any;
  cy?: any;
  stroke?: any;
  payload?: any;
  value?: any;
}

class CustomizedDot extends React.Component<ICDotProps> {
  render() {
    const { cx, cy, stroke, payload, value } = this.props;
    console.log("CustomizedDot", cx, cy, stroke, payload, value);

    /*
    if (payload.is_test) {
      return <circle cx={cx} cy={cy} r={6} fill="orange" />;
    }
    */

    return <circle cx={cx} cy={cy} r={6} fill="green" />;
  }
}

export default Finances;
