import React, { useState, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

const schema = yup.object({
  title: yup.string().required(),
});

const Edit = (props: any) => {
  console.log("Edit", props);
  const section = "packages-types";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState("");

  const initialDataState = {
    id: 0,
    title: "",
  };

  const [currentData, setCurrentData] = useState(initialDataState);

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, section, id)
      .then((response: any) => {
        console.log("Edit.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setCurrentData(response.data);
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/settings/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                Api.edit(
                  userData.session_hash,
                  section,
                  currentData.id,
                  formData
                ).then(
                  (response) => {
                    console.log("Edit.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      history.push(`/settings/${section}/list`);
                    }

                    setSubmitting(false);
                  },
                  (error) => {
                    console.log("Edit.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={{
                title: currentData.title,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      placeholder=""
                      isInvalid={!!errors.title}
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading" : ""}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Edit;
