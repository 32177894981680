import React from "react";
import AuthService from "../services/auth";

export type UserDataType = {
  id: number;
  username: string;
  fullname: string;
  roles: Array<string>;
  session_hash: string;
};

const DefaultUserData = {
  id: 0,
  username: "",
  fullname: "",
  roles: [],
  session_hash: "",
};

export type UserContextType = {
  isAuthorized: boolean;
  userData: UserDataType;
  logIn: (data: UserDataType) => void;
  logOut: () => void;
  authFailed: () => void;
};

export const UserContext = React.createContext({} as UserContextType);

export const UserProvider: React.FC = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);
  const [userData, setUserData] = React.useState<UserDataType>(DefaultUserData);

  const logIn = (data: UserDataType) => {
    console.log("UserProvider.logIn", data);

    setUserData(data);
    setIsAuthorized(true);
  };

  const logOut = () => {
    console.log("UserProvider.logOut");

    AuthService.logout(userData.session_hash);
    setUserData(DefaultUserData);
    setIsAuthorized(false);
  };

  const authFailed = () => {
    console.log("UserProvider.authFailed");

    // setUserData(DefaultUserData);
    setIsAuthorized(false);
  };

  React.useEffect(() => {
    const storedData = AuthService.getCurrentUser();

    console.log("UserProvider.storedData", storedData);

    if (storedData && storedData?.id) {
      console.log("UserProvider.setUserData");
      setUserData(storedData);
      setIsAuthorized(true);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ userData, isAuthorized, logIn, logOut, authFailed }}
    >
      {children}
    </UserContext.Provider>
  );
};
