import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const schema = yup.object({
  title: yup.string().required(),
});

type daysType = string;

type mealsTypesType = {
  id: number;
  title: string;
};

type mealsType = {
  id: number;
  title: string;
};

type mealTypesConnType = {
  [key: string]: mealsType;
};

type initialDataType = {
  id: number;
  title: string;
  status: number;
  days_available: Array<daysType>;
  meals_types_available: Array<mealsTypesType>;
  meals_available: Array<mealsType>;
  meals: mealTypesConnType;
};

const Add = () => {
  const section = "weeks";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [addNew, setAddNew] = useState(false);

  const initialDataState = {
    id: 0,
    title: "",
    status: 0,
    days_available: [],
    meals_types_available: [],
    meals_available: [],
    meals: {},
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = () => {
    Api.getAll(userData.session_hash, `include/menu-${section}/`)
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/menu/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                console.log("onSubmit", formData, initialData);

                Api.add(
                  userData.session_hash,
                  "menu-" + section,
                  formData
                ).then(
                  (response) => {
                    console.log("Add.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                      setSubmitting(false);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      if (addNew) {
                        history.push("/empty");
                        history.replace(`/menu/${section}/add`);
                      } else history.push(`/menu/${section}/list`);
                    }
                  },
                  (error) => {
                    console.log("Add.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/*console.log("errors", errors)*/}
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          name="title"
                          isInvalid={!!errors.title}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option
                            value="0"
                            className="text-warning font-weight-bold"
                          >
                            Inactive
                          </option>
                          <option
                            value="1"
                            className="text-success font-weight-bold"
                          >
                            Active
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Meals */}
                  <Row>
                    <Col>
                      <Table className="uc-menus-weeks" striped bordered hover>
                        <thead>
                          <tr>
                            <th></th>
                            {values.days_available.map((day, dindex) => (
                              <th
                                className="text-center"
                                key={`th-day-${dindex}`}
                              >
                                {day}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {values.meals_types_available.map((meal, mindex) => (
                            <tr key={`tr-meal-${mindex}`}>
                              <td className="font-weight-bold text-nowrap">
                                {meal.title}
                              </td>
                              {values.days_available.map((day, dindex) => {
                                let selectedValues = [];
                                if (
                                  values.meals[meal.id + "-" + day] !==
                                  undefined
                                ) {
                                  selectedValues.push(
                                    values.meals[meal.id + "-" + day]
                                  );
                                }

                                return (
                                  <td key={`td-day-${dindex}`}>
                                    <Typeahead
                                      id={`meals-selector-${mindex}-${dindex}`}
                                      style={{ color: "red !important" }}
                                      multiple={false}
                                      allowNew={false}
                                      onBlur={(e: any) => {
                                        const mealSelected =
                                          values.meals[meal.id + "-" + day];

                                        console.log(
                                          "Typeahead.onBlur",
                                          mealSelected,
                                          values.meals,
                                          e
                                        );

                                        if (
                                          mealSelected === undefined ||
                                          mealSelected.id === 0
                                        ) {
                                          let newMeals = {
                                            ...values.meals,
                                          };
                                          delete newMeals[meal.id + "-" + day];

                                          setFieldValue("meals", newMeals);
                                        }

                                        /* mealConn[meal.id + "-" + day] = {
                                        id: 0,
                                        title: text,
                                      }; */
                                      }}
                                      onChange={(selected: any) => {
                                        const selectedItem =
                                          selected.length > 0
                                            ? { ...selected[0] }
                                            : {};
                                        const mealTitle =
                                          selected.length > 0
                                            ? selectedItem.title
                                            : "";
                                        const mealId =
                                          selected.length > 0
                                            ? selectedItem.id
                                            : 0;

                                        /*
                                        Add new meal to the list
                                      */
                                        if (mealId > 0) {
                                          let mealConn = {} as mealTypesConnType;
                                          mealConn[
                                            meal.id + "-" + day
                                          ] = selectedItem;
                                          let newMeals = {
                                            ...values.meals,
                                            ...mealConn,
                                          };

                                          /* // Remove duplicated
                                        newIngredients = newIngredients.filter(
                                          (thing, index, self) =>
                                            index ===
                                            self.findIndex(
                                              (t) => t.id === thing.id
                                            )
                                        ); */

                                          console.log(
                                            "meals-selector.onChange",
                                            mealConn,
                                            newMeals,
                                            values.meals
                                          );
                                          setFieldValue("meals", newMeals);

                                          /* let mealTitleConn = {} as mealTitlesConnType;
                                        mealTitleConn[
                                          meal.id + "-" + day
                                        ] = mealTitle;
                                        let newMealsTitles = {
                                          ...values.meals_selector,
                                          mealTitleConn,
                                        };

                                        setFieldValue(
                                          "meals_selector",
                                          newMealsTitles
                                        ); */
                                        }
                                      }}
                                      onInputChange={(
                                        text: any,
                                        event: any
                                      ) => {
                                        // console.log("onInputChange", text);

                                        let mealConn = {} as mealTypesConnType;
                                        mealConn[meal.id + "-" + day] = {
                                          id: 0,
                                          title: text,
                                        };
                                        let newMeals = {
                                          ...values.meals,
                                          ...mealConn,
                                        };

                                        setFieldValue("meals", newMeals);
                                      }}
                                      /* newSelectionPrefix="New ingredient: " */
                                      /* onBlur={(e: any) => setFieldTouched("title", true)} */
                                      labelKey="title"
                                      options={values.meals_available}
                                      placeholder="Type meal to add.."
                                      selected={selectedValues}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-5 pb-5">
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        className={isSubmitting ? "btn--loading" : ""}
                        onClick={() => {
                          setAddNew(false);
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? (
                          <div className="lds-dual-ring"></div>
                        ) : null}
                        Save
                      </Button>
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        className={isSubmitting ? "btn--loading ml-3" : " ml-3"}
                        onClick={() => {
                          setAddNew(true);
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? (
                          <div className="lds-dual-ring"></div>
                        ) : null}
                        Save & Add another
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Add;
