import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Alert,
  Table,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import useModal from "hooks/useModal";
import Modal from "components/elements/Modal";

const ingredientsSchema = yup.object().shape({
  // brutto: yup.number().moreThan(0).required(),
  netto: yup.number().moreThan(0).required(),
});
const schema = yup.object({
  title: yup.string().required(),
  amount: yup.number().moreThan(0).required(),
  units_id: yup.number().moreThan(0),
  kcal: yup.number(),
  protein: yup.number(),
  fat: yup.number(),
  carbs: yup.number(),
  recipe: yup.string(),
  ingredients: yup.array().of(ingredientsSchema).min(1).required(),
});

type unitsTypes = {
  id: number;
  title: string;
};

type ingredientsTypes = {
  id: number;
  title: string;
  units_name: string;
  loss_percent: number;
  brutto: number;
  netto: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  units_id: number;
  ks_qty: number;
};

type initialDataType = {
  id: number;
  title: string;
  amount?: number | string;
  units_id: number;
  kcal?: number | string;
  protein?: number | string;
  fat?: number | string;
  carbs?: number | string;
  recipe?: string;
  units: Array<unitsTypes>;
  ingredients: Array<ingredientsTypes>;
  ingredients_available: Array<ingredientsTypes>;
  ingredients_selector: string;
};

type TotalNumbersType = {
  brutto: number;
  netto: number;
  kcal: number | string;
  protein: number | string;
  fat: number | string;
  carbs: number | string;
};

const Add = () => {
  const section = "semifinished";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showKsQty, setShowKsQty] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [totalIngredients, setTotalIngredients] = useState<TotalNumbersType>({
    brutto: 0,
    netto: 0,
    kcal: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });

  type buttonType = {
    title: string;
    onClick: () => void;
  };
  type ModalType = {
    title: string;
    message: string;
    button?: buttonType;
  };
  const { modalIsOpen, modalToggle } = useModal();
  const [modalData, setModalData] = useState<ModalType>({
    title: "",
    message: "",
  });

  const initialDataState = {
    id: 0,
    title: "",
    amount: "",
    units_id: 0,
    recipe: "",
    units: [],
    ingredients: [],
    ingredients_available: [],
    ingredients_selector: "",
  };

  const [initialData, setInitialData] =
    useState<initialDataType>(initialDataState);

  const getData = () => {
    Api.getAll(userData.session_hash, "include/meals-semifinished/")
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const recalcTotalNumbers = (newIngredients: Array<ingredientsTypes>) => {
    // console.log("recalcTotalNumbers", newIngredients);
    let newTotals = {
      brutto: 0,
      netto: 0,
      kcal: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
    };

    newIngredients.map((item, index) => {
      let itemBrutto = parseFloat(item.brutto as any);
      let itemNetto = parseFloat(item.netto as any);

      newTotals.brutto += isNaN(itemBrutto)
        ? 0
        : itemBrutto < 0
        ? 0
        : itemBrutto;
      newTotals.netto += isNaN(itemNetto) ? 0 : itemNetto < 0 ? 0 : itemNetto;

      const foundIndex = initialData.ingredients_available.findIndex(
        (x) => x.id == item.id
      );
      const originalIngredient = initialData.ingredients_available[foundIndex];

      const itemPercent = (item.netto * 100) / originalIngredient.netto;
      // newTotals.kcal += originalIngredient.kcal * (itemPercent / 100);
      newTotals.kcal +=
        itemNetto * (originalIngredient.kcal / originalIngredient.brutto);
      newTotals.protein += originalIngredient.protein * (itemPercent / 100);
      newTotals.fat += originalIngredient.fat * (itemPercent / 100);
      newTotals.carbs += originalIngredient.carbs * (itemPercent / 100);
      console.log(
        "recalcTotalNumbers",
        initialData.ingredients_available,
        originalIngredient,
        item.netto,
        originalIngredient.netto,
        itemPercent
      );
    });

    newTotals.brutto = Math.round(newTotals.brutto * 100) / 100;
    newTotals.netto = Math.round(newTotals.netto * 100) / 100;
    newTotals.kcal = Math.round(newTotals.kcal * 100) / 100;
    newTotals.protein = Math.round(newTotals.protein * 100) / 100;
    newTotals.fat = Math.round(newTotals.fat * 100) / 100;
    newTotals.carbs = Math.round(newTotals.carbs * 100) / 100;
    setTotalIngredients(newTotals);
  };

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/meals/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xl="10">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                console.log("formData.1", { ...formData }, totalIngredients);

                // Save total numbers
                formData.kcal = totalIngredients.kcal;
                formData.protein = totalIngredients.protein;
                formData.carbs = totalIngredients.carbs;
                formData.fat = totalIngredients.fat;

                console.log("formData.2", { ...formData });

                Api.add(
                  userData.session_hash,
                  "meals-" + section,
                  formData
                ).then(
                  (response) => {
                    console.log("Add.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                      setSubmitting(false);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      if (addNew) {
                        history.push("/empty");
                        history.replace(`/meals/${section}/add`);
                      } else history.push(`/meals/${section}/list`);
                    }
                  },
                  (error) => {
                    console.log("Add.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/*console.log("errors", errors)*/}
                  <Row>
                    <Col sm="12" md="6">
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          name="title"
                          isInvalid={!!errors.title}
                        />
                      </Form.Group>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Unit</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.units_id}
                            onChange={(e) => {
                              if (e.target.value === "1") {
                                if (!showKsQty) setShowKsQty(true);
                              } else {
                                if (showKsQty) setShowKsQty(false);
                              }
                              handleChange(e);
                            }}
                            name="units_id"
                            isInvalid={!!errors.units_id}
                          >
                            <option value="0">--</option>
                            {initialData.units.map((type) => (
                              <option value={type.id} key={`unit-${type.id}`}>
                                {type.title}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.amount}
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }
                              handleChange(e);
                            }}
                            name="amount"
                            isInvalid={!!errors.amount}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>kCal</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalIngredients.kcal}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...totalIngredients };
                              newTotals.kcal = e.target.value;
                              setTotalIngredients(newTotals);

                              handleChange(e);
                            }}
                            name="kcal"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Bilkoviny</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalIngredients.protein}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...totalIngredients };
                              newTotals.protein = e.target.value;
                              setTotalIngredients(newTotals);

                              handleChange(e);
                            }}
                            name="protein"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Sacharidy</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalIngredients.carbs}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...totalIngredients };
                              newTotals.carbs = e.target.value;
                              setTotalIngredients(newTotals);

                              handleChange(e);
                            }}
                            name="carbs"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Tuky</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalIngredients.fat}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...totalIngredients };
                              newTotals.fat = e.target.value;
                              setTotalIngredients(newTotals);

                              handleChange(e);
                            }}
                            name="fat"
                          />
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    <Col sm="12" md="6">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Recept</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={values.recipe}
                          name="recipe"
                          rows={6}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Row className="my-3">
                    <Form.Group as={Col}>
                      <div className="uc-hrdivider mt-4">
                        <hr />
                        <span>Ingredients</span>
                      </div>
                      <Table striped bordered hover className="table-middle">
                        <thead>
                          <tr>
                            <th>Ingredient</th>
                            <th>Unit</th>
                            <th>%</th>
                            <th>Brutto</th>
                            <th>Netto</th>
                            <th>kCal</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.ingredients.map((item, index) => (
                            <tr key={`table-row-${item.id}`}>
                              <td>{item.title}</td>
                              <td>{item.units_name}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.loss_percent}
                                  onChange={(e) => {
                                    let loss_percent = e.target.value;
                                    if (loss_percent.includes(",")) {
                                      loss_percent = loss_percent.replace(
                                        /,/,
                                        "."
                                      );
                                    }

                                    let newIngredients = values.ingredients;
                                    const foundIndex = newIngredients.findIndex(
                                      (x) => x.id == item.id
                                    );
                                    newIngredients[foundIndex].loss_percent =
                                      loss_percent as any;

                                    /*
                                      Calc brutto
                                    */

                                    let brutto =
                                      Math.round(
                                        ((item.netto * 100) /
                                          (100 - parseFloat(loss_percent))) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(brutto)) brutto = 0;
                                    newIngredients[foundIndex].brutto =
                                      brutto as any;

                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    recalcTotalNumbers(newIngredients);
                                  }}
                                  name={`ingredient-${item.id}-loss_percent`}
                                  /* isInvalid={!!errors[`ingredient-${item.id}-loss_percent`} */
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.brutto}
                                  readOnly={true}
                                  onChange={(e) => {
                                    let brutto = e.target.value;
                                    if (brutto.includes(",")) {
                                      brutto = brutto.replace(/,/, ".");
                                    }

                                    let newIngredients = values.ingredients;
                                    const foundIndex = newIngredients.findIndex(
                                      (x) => x.id == item.id
                                    );
                                    newIngredients[foundIndex].brutto =
                                      brutto as any;

                                    /*
                                      Calc netto
                                    */

                                    let netto =
                                      Math.round(
                                        parseFloat(brutto) *
                                          ((100 - item.loss_percent) / 100) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(netto)) netto = 0;
                                    newIngredients[foundIndex].netto =
                                      netto as any;

                                    console.log(
                                      "setFieldValue",
                                      newIngredients,
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    recalcTotalNumbers(newIngredients);

                                    console.log(
                                      "setFieldValue",
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                  }}
                                  name={`ingredient-${item.id}-brutto`}
                                  isInvalid={
                                    errors.ingredients &&
                                    errors.ingredients instanceof Array &&
                                    errors.ingredients.length >= index + 1 &&
                                    errors.ingredients[index] !== undefined &&
                                    "brutto" in
                                      (errors.ingredients[index] as any)
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Row>
                                  {item.units_id === 1 ? (
                                    <>
                                      <Col>
                                        <InputGroup className="mb-2 mr-sm-2">
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              ks:
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <Form.Control
                                            type="text"
                                            style={{ maxWidth: "80px" }}
                                            value={item.ks_qty}
                                            onChange={(e) => {
                                              let ks_qty = e.target.value;
                                              if (ks_qty.includes(",")) {
                                                ks_qty = ks_qty.replace(
                                                  /,/,
                                                  "."
                                                );
                                              }

                                              let newIngredients =
                                                values.ingredients;
                                              const foundIndex =
                                                newIngredients.findIndex(
                                                  (x) => x.id == item.id
                                                );
                                              newIngredients[
                                                foundIndex
                                              ].ks_qty = ks_qty as any;

                                              const foundAvailableIndex =
                                                values.ingredients_available.findIndex(
                                                  (x) => x.id == item.id
                                                );

                                              /*
                                                Calc netto
                                              */

                                              let netto =
                                                Math.round(
                                                  values.ingredients_available[
                                                    foundAvailableIndex
                                                  ].netto *
                                                    parseFloat(ks_qty) *
                                                    100
                                                ) / 100;

                                              if (Number.isNaN(netto))
                                                netto = 0;
                                              newIngredients[foundIndex].netto =
                                                netto as any;

                                              /*
                                                kCal
                                              */

                                              let kcal =
                                                Math.round(
                                                  (netto as any) *
                                                    (values
                                                      .ingredients_available[
                                                      foundAvailableIndex
                                                    ].kcal /
                                                      values
                                                        .ingredients_available[
                                                        foundAvailableIndex
                                                      ].brutto) *
                                                    100
                                                ) / 100;
                                              newIngredients[foundIndex].kcal =
                                                kcal as any;

                                              /*
                                                Calc brutto
                                              */

                                              let brutto =
                                                Math.round(
                                                  values.ingredients_available[
                                                    foundAvailableIndex
                                                  ].brutto *
                                                    parseFloat(ks_qty) *
                                                    100
                                                ) / 100;

                                              if (Number.isNaN(brutto))
                                                brutto = 0;
                                              newIngredients[
                                                foundIndex
                                              ].brutto = brutto as any;

                                              console.log(
                                                "setFieldValue",
                                                newIngredients,
                                                values.ingredients,
                                                values.ingredients_available
                                              );

                                              setFieldValue(
                                                "ingredients",
                                                newIngredients
                                              );

                                              recalcTotalNumbers(
                                                newIngredients
                                              );

                                              console.log(
                                                "setFieldValue",
                                                values.ingredients,
                                                values.ingredients_available
                                              );
                                            }}
                                            name={`ingredient-${item.id}-ks_qty`}
                                            isInvalid={
                                              errors.ingredients &&
                                              errors.ingredients instanceof
                                                Array &&
                                              errors.ingredients.length >=
                                                index + 1 &&
                                              errors.ingredients[index] !==
                                                undefined &&
                                              "ks_qty" in
                                                (errors.ingredients[
                                                  index
                                                ] as any)
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                          />
                                        </InputGroup>
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col>
                                    <Form.Control
                                      type="text"
                                      style={{ maxWidth: "80px" }}
                                      value={item.netto}
                                      readOnly={
                                        item.units_id === 1 ? true : false
                                      }
                                      onChange={(e) => {
                                        let netto = e.target.value;
                                        if (netto.includes(",")) {
                                          netto = netto.replace(/,/, ".");
                                        }

                                        let newIngredients = values.ingredients;
                                        const foundIndex =
                                          newIngredients.findIndex(
                                            (x) => x.id == item.id
                                          );
                                        newIngredients[foundIndex].netto =
                                          netto as any;

                                        /*
                                          kCal
                                        */

                                        let availableIngredients =
                                          values.ingredients_available;
                                        const foundAvailableIndex =
                                          availableIngredients.findIndex(
                                            (x) => x.id == item.id
                                          );
                                        let kcal =
                                          Math.round(
                                            (netto as any) *
                                              (availableIngredients[
                                                foundAvailableIndex
                                              ].kcal /
                                                availableIngredients[
                                                  foundAvailableIndex
                                                ].brutto) *
                                              100
                                          ) / 100;
                                        newIngredients[foundIndex].kcal =
                                          kcal as any;

                                        /*
                                          Calc brutto
                                        */

                                        let brutto =
                                          Math.round(
                                            ((parseFloat(netto) * 100) /
                                              (100 - item.loss_percent)) *
                                              100
                                          ) / 100;

                                        if (Number.isNaN(brutto)) brutto = 0;
                                        newIngredients[foundIndex].brutto =
                                          brutto as any;

                                        console.log(
                                          "setFieldValue",
                                          newIngredients,
                                          values.ingredients,
                                          values.ingredients_available
                                        );
                                        setFieldValue(
                                          "ingredients",
                                          newIngredients
                                        );

                                        recalcTotalNumbers(newIngredients);

                                        console.log(
                                          "setFieldValue",
                                          values.ingredients,
                                          values.ingredients_available
                                        );
                                      }}
                                      name={`ingredient-${item.id}-netto`}
                                      isInvalid={
                                        errors.ingredients &&
                                        errors.ingredients instanceof Array &&
                                        errors.ingredients.length >=
                                          index + 1 &&
                                        errors.ingredients[index] !==
                                          undefined &&
                                        "netto" in
                                          (errors.ingredients[index] as any)
                                          ? true
                                          : false
                                      }
                                      autoComplete="off"
                                    />
                                  </Col>
                                </Form.Row>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.kcal}
                                  readOnly={true}
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    setModalData({
                                      title: "Confirmation",
                                      message:
                                        "Do you really want to remove this ingredient?",
                                      button: {
                                        title: "Yes",
                                        onClick: () => {
                                          console.log("yes");

                                          const newIngredients =
                                            values.ingredients.filter(function (
                                              obj
                                            ) {
                                              return obj.id !== item.id;
                                            });

                                          setFieldValue(
                                            "ingredients",
                                            newIngredients
                                          );

                                          recalcTotalNumbers(newIngredients);
                                        },
                                      },
                                    });
                                    modalToggle();
                                  }}
                                  className={`inline-action-item text-danger`}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th colSpan={3}></th>
                            <th>{totalIngredients.brutto}</th>
                            <th>{totalIngredients.netto}</th>
                            <th>{totalIngredients.kcal}</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td colSpan={7}>
                              <Typeahead
                                id="ingredient-selector"
                                multiple={false}
                                allowNew={false}
                                onChange={(selected: any) => {
                                  const selectedItem =
                                    selected.length > 0
                                      ? { ...selected[0] }
                                      : {};
                                  const value =
                                    selected.length > 0
                                      ? selectedItem.title
                                      : "";

                                  /*
                                    Add new ingredient to the list
                                  */
                                  if (value != "") {
                                    selectedItem.brutto = 0;
                                    selectedItem.netto = 0;
                                    selectedItem.ks_qty = 0;
                                    selectedItem.kcal = 0;
                                    let newIngredients = [
                                      ...values.ingredients,
                                      selectedItem,
                                    ];

                                    // Remove duplicated
                                    newIngredients = newIngredients.filter(
                                      (thing, index, self) =>
                                        index ===
                                        self.findIndex((t) => t.id === thing.id)
                                    );

                                    console.log(
                                      "ingredient-selector.onChange",
                                      newIngredients,
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    setFieldValue("ingredients_selector", "");
                                  }
                                }}
                                onInputChange={(text: any, event: any) => {
                                  console.log(
                                    "ingredient-selector.onInputChange",
                                    text
                                  );
                                  setFieldValue("ingredients_selector", text);
                                }}
                                newSelectionPrefix="New ingredient: "
                                /* onBlur={(e: any) => setFieldTouched("title", true)} */
                                labelKey="title"
                                options={values.ingredients_available}
                                placeholder="Type ingredient name to add.."
                                selected={[values.ingredients_selector]}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Form.Row>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading" : ""}
                    onClick={() => {
                      setAddNew(false);
                      handleSubmit();
                    }}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Save
                  </Button>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading ml-3" : " ml-3"}
                    onClick={() => {
                      setAddNew(true);
                      handleSubmit();
                    }}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Save & Add another
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>

      {modalData ? (
        <Modal
          title={modalData.title}
          message={modalData.message}
          button={modalData.button}
          isOpen={modalIsOpen}
          toggle={modalToggle}
        />
      ) : null}
    </>
  );
};

export default Add;
