/**
 * System libraries
 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

/**
 * Modules
 */
import { versionsCompare } from "utils/api";

const packageJson = require("../../package.json");

/**
 * Version control logics.
 * Shows alert for a refresh when a new version is out.
 */
export default function VersionControl() {
  // console.log('VersionControl')

  /*
		System
	*/

  // History
  let history = useHistory();

  /*
		States
	*/

  // Do we need to show the alert?
  const [showAlert, setShowAlert] = useState(false);

  // Are we reloading?
  const [showReloading, setShowReloading] = useState(false);

  /*
		App reload function
	*/
  const appReload = () => {
    console.log("VersionControl.appReload");

    // We are in a reloading state
    setShowReloading(true);

    // Set timeout (for everything to be saved)
    setTimeout(() => {
      // Do the full reload!
      history.go(0);
    }, 5 * 1000);
  };

  /*
		Run once
	*/
  useEffect(() => {
    // console.log('VersionControl.Mount')

    /*
			System checks
		*/

    // Invalid version
    if (!packageJson?.version) {
      console.log("VersionControl.invalidVersion");
      return () => {};
    }

    console.log("VersionControl.Version", packageJson.version);

    /*
			Version check Timer function
		*/
    let versionTimeout: any = null;
    const setVersionCheckTimeout = (timeout: number) => {
      // console.log('VersionControl.setTimeout')

      // Set timeout
      versionTimeout = setTimeout(() => {
        // console.log('VersionControl.Timeout')

        // Clear timeout
        versionTimeout = null;

        /*
					Prepare request data
				*/

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        /*
					Send request
				*/
        fetch("https://mgr.uncook.app/api/version/", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            // console.log('VersionControl.Send.Response', data, packageJson.version)

            // New version?
            if (
              // Got version in reply
              data?.data?.version &&
              // Versions are different
              data.data.version !== packageJson.version &&
              // Version is higher
              versionsCompare(data.data.version, packageJson.version) === 1
            ) {
              console.log("VersionControl.Updated", data.data.version);

              // Show alert!
              setShowAlert(true);

              // Version is the same
            } else {
              console.log(
                "VersionControl.OK",
                data.data.version,
                packageJson.version
              );

              // Set a new Timer
              setVersionCheckTimeout(5 * 60 * 1000);
            }
          });
      }, timeout);
    };

    /*
			Initialize the first Timer to check version
		*/
    setVersionCheckTimeout(10);

    /*
			Component unmounts?
		*/
    return () => {
      // console.log('VersionControl.Unmount')

      // Do we need to clear the timer?
      if (versionTimeout !== null) {
        clearTimeout(versionTimeout);
      }
    };
  }, []);

  /*
		Do we need to show the alert?
	*/

  // Yeap, show it!
  if (showAlert) {
    return (
      <Alert variant="warning">
        <div
          className={
            "uc-update__content uc-update__content_update" +
            (showReloading ? "" : " js-active")
          }
        >
          <h4 className={"uc-update__title"}>New update available</h4>
          <Button
            variant="warning"
            className={"uc-update__button"}
            onClick={appReload}
          >
            Update
          </Button>
        </div>
        <div
          className={
            "uc-update__content uc-update__content_loading" +
            (showReloading ? " js-active" : "")
          }
        >
          <h4 className={"uc-update__title"}>Reloading, please wait…</h4>
        </div>
      </Alert>
    );

    // No output is required
  } else return null;
}
