import {
  xlsxGenerate,
  xlsxGenerateStyled,
  SheetsType,
  SheetsDetailedType,
  CellStyles,
  utilsStyleRange,
} from "utils/xlsx";

import { wordWrap } from "utils/strings";

type SemifinishedType = {
  id: number;
  title: string;
  netto: number;
  qty: number;
};

type IngredientsType = {
  id: number;
  title: string;
  brutto: number;
  netto: number;
  qty: number;
  group_title?: string;
};

type MealType = {
  // clients: Array<CustomersType>;
  ingredients: Array<IngredientsType>;
  semifinished: Array<SemifinishedType>;
  title: string;
  qty: number;
  netto: number;
  // meals_types_title: string;
  // meals_types_id: number;
  // groups: Array<GroupType>;
};

type MealInfoType = {
  [key: string]: MealType;
};

type MealDateInfoType = {
  meals: MealInfoType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_netto: number;
  meals_qty: number;
};

type MealDatesType = {
  [key: string]: MealDateInfoType;
};

/**
 * Generate xlsx with Tech cards for the Kitchen
 */
export const techCardsXLSX = (
  selectedCookDay: string,
  mealsData: MealDatesType
) => {
  const sheets = [] as SheetsDetailedType;
  const usedSheetTitles = [] as Array<string>;

  // Days
  Object.keys(mealsData).map((dayKey, index) => {
    const currentDay = mealsData[dayKey];

    // Meals
    Object.keys(currentDay.meals).map((mealKey, index) => {
      const currentMeal = currentDay.meals[mealKey] as any;
      const merges = [] as Array<string>;
      const styles = [] as CellStyles;

      // Meal Title
      const mealDataArray = [[currentMeal.title]];
      styles.push({ cell: "A1", style: { font: { sz: 17, bold: true } } });
      merges.push("A1:J1");

      // Meal Recipe
      const currentMealRecipe = currentMeal.recipe.trim();
      if (currentMealRecipe !== "") {
        // console.log("currentMealRecipe", currentMealRecipe, currentMeal);
        const currentMealRecipeLines = currentMealRecipe.split("\n");
        currentMealRecipeLines.map((currentRecipeLine: string) => {
          // console.log("currentMealRecipe.line", currentRecipeLine);
          const longLines = wordWrap(currentRecipeLine, 90);
          longLines.map((currentLongLine: string) => {
            // console.log("currentMealRecipe.longLines", currentLongLine);
            mealDataArray.push([currentLongLine.trim()]);
            merges.push(
              "A" + mealDataArray.length + ":J" + mealDataArray.length
            );
          });
        });
        // mealDataArray.push(currentMealRecipeLines);
      }
      mealDataArray.push([""]);

      // Totals
      {
        mealDataArray.push([
          "Brutto, celkem",
          "",
          "",
          "Netto, celkem",
          "",
          "Porce váha",
          "Celková váha",
          "Brutto, porce",
          "",
          "Netto, porce",
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
        // merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
        merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            font: { bold: true },
            border: { top: { style: "thin" } },
          },
          "A",
          "J",
          mealDataArray.length
        );
        mealDataArray.push([
          Math.round(currentMeal.brutto),
          "",
          "",
          Math.round(currentMeal.netto),
          "",
          Math.round(currentMeal.amount),
          Math.round(currentMeal.amount * currentMeal.qty),
          Math.round(currentMeal.brutto_single),
          "",
          Math.round(currentMeal.netto_single),
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
        // merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
        merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            alignment: { horizontal: "left" },
            border: { bottom: { style: "thin" } },
          },
          "A",
          "J",
          mealDataArray.length
        );
      }

      // Meal nutritions
      {
        mealDataArray.push([
          "kCal",
          "",
          "",
          "Bílkoviny",
          "",
          "Tuky",
          "",
          "Sacharidy",
          "",
          "",
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
        merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
        merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            font: { bold: true },
            border: { top: { style: "thin" } },
          },
          "A",
          "J",
          mealDataArray.length
        );
        mealDataArray.push([
          currentMeal.kcal,
          "",
          "",
          currentMeal.protein,
          "",
          currentMeal.fat,
          "",
          currentMeal.carbs,
          "",
          "",
        ]);
        merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
        merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
        merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
        merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            alignment: { horizontal: "left" },
            border: { bottom: { style: "thin" } },
          },
          "A",
          "J",
          mealDataArray.length
        );
      }

      mealDataArray.push([""]);

      /*
        Ingredients (simple, single via, all ingredients in one table)
      * /
      {
        // Title
        mealDataArray.push(["Suroviny"]);
        merges.push("A" + mealDataArray.length + ":J" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            font: { sz: 13, bold: true },
            border: { bottom: { style: "thin" } },
          },
          "A",
          "J",
          mealDataArray.length
        );

        // Summary
        {
          // Calc summaries
          let ingredientSumBrutto = 0;
          let ingredientSumNetto = 0;
          currentMeal.ingredients.map((item: any, index: any) => {
            ingredientSumBrutto += item.brutto;
            ingredientSumNetto += item.netto;
          });

          mealDataArray.push([
            "Brutto, celkem",
            "",
            "",
            "Netto, celkem",
            "",
            "",
            "",
          ]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
            },
            "A",
            "J",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          mealDataArray.push([
            Math.round(ingredientSumBrutto),
            "",
            "",
            Math.round(ingredientSumNetto),
            "",
            "",
            "",
            "",
            "",
            "",
          ]);
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":F" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "left" },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "J",
            mealDataArray.length
          );
        }

        // Header
        mealDataArray.push([
          "#",
          "Surovina",
          "",
          "",
          "Celk brut",
          "Celk net",
          "Porc brut",
          "Porc net",
          "Množství",
        ]);
        merges.push("B" + mealDataArray.length + ":D" + mealDataArray.length);
        utilsStyleRange(
          styles,
          {
            alignment: { horizontal: "right" },
            border: { right: { style: "thin" } },
          },
          "E",
          "I",
          mealDataArray.length
        );

        // List of ingredients
        currentMeal.ingredients.map((item: any, index: any) => {
          mealDataArray.push([
            index + 1,
            item.title,
            "",
            "",
            Math.round(item.brutto * 100) / 100,
            Math.round(item.netto * 100) / 100,
            Math.round(item.brutto_single * 100) / 100,
            Math.round(item.netto_single * 100) / 100,
            item.qty,
          ]);
          merges.push("B" + mealDataArray.length + ":D" + mealDataArray.length);
          styles.push({
            cell: "A" + mealDataArray.length,
            style: { alignment: { horizontal: "left" } },
          });
          utilsStyleRange(
            styles,
            {
              border: { right: { style: "thin" } },
            },
            "E",
            "I",
            mealDataArray.length
          );
        });
      }

      mealDataArray.push([""]);
      */

      /*
        Ingredients by groups
      */

      {
        // Get groups
        let ingredientsByGroups = {} as any;
        {
          ingredientsByGroups["Bez skupiny"] = [];

          currentMeal.ingredients.map((item: any, index: any) => {
            if (!item.meals_groups_title) {
              ingredientsByGroups["Bez skupiny"].push(item);
            } else {
              if (!(item.meals_groups_title in ingredientsByGroups)) {
                ingredientsByGroups[item.meals_groups_title] = [];
              }

              ingredientsByGroups[item.meals_groups_title].push(item);
            }
          });

          console.log("ingredientsByGroups", ingredientsByGroups);
        }

        Object.keys(ingredientsByGroups).map(function (
          ingredientsGroupKey,
          index
        ) {
          const currentGroupIngredients =
            ingredientsByGroups[ingredientsGroupKey];

          // Title
          mealDataArray.push(["Suroviny: " + ingredientsGroupKey]);
          merges.push("A" + mealDataArray.length + ":J" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              font: { sz: 13, bold: true },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "J",
            mealDataArray.length
          );

          // Summary
          {
            // Calc summaries
            let ingredientSumBrutto = 0;
            let ingredientSumNetto = 0;
            currentGroupIngredients.map((item: any, index: any) => {
              ingredientSumBrutto += item.brutto;
              ingredientSumNetto += item.netto;
            });

            mealDataArray.push([
              "Brutto, celkem",
              "",
              "",
              "Netto, celkem",
              "",
              "",
              "",
            ]);
            utilsStyleRange(
              styles,
              {
                font: { bold: true },
              },
              "A",
              "J",
              mealDataArray.length
            );
            merges.push(
              "A" + mealDataArray.length + ":C" + mealDataArray.length
            );
            merges.push(
              "D" + mealDataArray.length + ":F" + mealDataArray.length
            );
            mealDataArray.push([
              Math.round(ingredientSumBrutto),
              "",
              "",
              Math.round(ingredientSumNetto),
              "",
              "",
              "",
              "",
              "",
              "",
            ]);
            merges.push(
              "A" + mealDataArray.length + ":C" + mealDataArray.length
            );
            merges.push(
              "D" + mealDataArray.length + ":F" + mealDataArray.length
            );
            utilsStyleRange(
              styles,
              {
                alignment: { horizontal: "left" },
                border: { bottom: { style: "thin" } },
              },
              "A",
              "J",
              mealDataArray.length
            );
          }

          // Header
          mealDataArray.push([
            "#",
            "Surovina",
            "",
            "",
            "Celk brut",
            "Celk net",
            "Porc brut",
            "Porc net",
            "Množství",
          ]);
          merges.push("B" + mealDataArray.length + ":D" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "right" },
              border: { right: { style: "thin" } },
            },
            "E",
            "I",
            mealDataArray.length
          );

          // List of ingredients
          currentGroupIngredients.map((item: any, index: any) => {
            mealDataArray.push([
              index + 1,
              item.title,
              "",
              "",
              Math.round(item.brutto * 100) / 100,
              Math.round(item.netto * 100) / 100,
              Math.round(item.brutto_single * 100) / 100,
              Math.round(item.netto_single * 100) / 100,
              item.qty,
            ]);
            merges.push(
              "B" + mealDataArray.length + ":D" + mealDataArray.length
            );
            styles.push({
              cell: "A" + mealDataArray.length,
              style: { alignment: { horizontal: "left" } },
            });
            utilsStyleRange(
              styles,
              {
                border: { right: { style: "thin" } },
              },
              "E",
              "I",
              mealDataArray.length
            );
          });

          mealDataArray.push([""]);
        });
      }

      /*
        Semifinished
      */
      if (currentMeal.semifinished.length > 0) {
        /* mealDataArray.push(["Polotovar"]);
          mealDataArray.push([
            "#",
            "Polotovar",
            "Brutto, celkem",
            "Netto, celkem",
            "Brutto, porce",
            "Netto, porce",
            "Množství",
          ]); */
        currentMeal.semifinished.map((currentSemifinished: any, index: any) => {
          mealDataArray.push([
            "Polotovar #" + (index + 1) + ": " + currentSemifinished.title,
          ]);
          utilsStyleRange(
            styles,
            {
              font: { sz: 13, bold: true },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "J",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":J" + mealDataArray.length);

          // Meal Recipe
          if (currentSemifinished.recipe) {
            console.log("currentSemifinished.recipe", currentSemifinished);
            const currenSemifinishedRecipe = currentSemifinished.recipe.trim();
            if (currenSemifinishedRecipe !== "") {
              const currenSemifinishedRecipeLines =
                currenSemifinishedRecipe.split("\n");
              currenSemifinishedRecipeLines.map((currentRecipeLine: string) => {
                const longLines = wordWrap(currentRecipeLine, 90);
                longLines.map((currentLongLine: string) => {
                  mealDataArray.push([currentLongLine.trim()]);
                  merges.push(
                    "A" + mealDataArray.length + ":J" + mealDataArray.length
                  );
                });
              });
              // mealDataArray.push(currentMealRecipeLines);
            }
            mealDataArray.push([""]);
          }

          mealDataArray.push([
            // "#",
            //"",
            "Brutto, celkem",
            "",
            "",
            "Netto, celkem",
            "",
            "Brutto, porce",
            "",
            "Netto, porce",
            "",
            "Množství",
          ]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
            },
            "A",
            "J",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
          merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
          merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
          mealDataArray.push([
            // index + 1,
            // currentSemifinished.title,
            Math.round(currentSemifinished.brutto),
            "",
            "",
            Math.round(currentSemifinished.netto),
            "",
            Math.round(currentSemifinished.brutto_single),
            "",
            Math.round(currentSemifinished.netto_single),
            "",
            currentSemifinished.qty,
          ]);
          merges.push("A" + mealDataArray.length + ":C" + mealDataArray.length);
          merges.push("D" + mealDataArray.length + ":E" + mealDataArray.length);
          merges.push("F" + mealDataArray.length + ":G" + mealDataArray.length);
          merges.push("H" + mealDataArray.length + ":I" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "left" },
            },
            "A",
            "J",
            mealDataArray.length
          );

          // Semifinished ingredients
          mealDataArray.push(["Suroviny"]);
          utilsStyleRange(
            styles,
            {
              font: { bold: true },
              border: { bottom: { style: "thin" } },
            },
            "A",
            "J",
            mealDataArray.length
          );
          merges.push("A" + mealDataArray.length + ":J" + mealDataArray.length);
          mealDataArray.push([
            "#",
            "Surovina",
            "",
            "",
            "Celk brut",
            "Celk net",
            "Porc brut",
            "Porc net",
            "Množství",
          ]);
          merges.push("B" + mealDataArray.length + ":D" + mealDataArray.length);
          utilsStyleRange(
            styles,
            {
              alignment: { horizontal: "right" },
              border: { right: { style: "thin" } },
            },
            "E",
            "I",
            mealDataArray.length
          );
          Object.keys(currentSemifinished.ingredients).map(
            (semifinishedIngredientKey, index) => {
              const semifinishedIngredient = currentSemifinished.ingredients[
                semifinishedIngredientKey
              ] as any;
              mealDataArray.push([
                index + 1,
                semifinishedIngredient.title,
                "",
                "",
                Math.round(semifinishedIngredient.brutto * 100) / 100,
                Math.round(semifinishedIngredient.netto * 100) / 100,
                Math.round(semifinishedIngredient.brutto_single * 100) / 100,
                Math.round(semifinishedIngredient.netto_single * 100) / 100,
                semifinishedIngredient.qty,
              ]);
              merges.push(
                "B" + mealDataArray.length + ":D" + mealDataArray.length
              );
              styles.push({
                cell: "A" + mealDataArray.length,
                style: { alignment: { horizontal: "left" } },
              });
              utilsStyleRange(
                styles,
                {
                  border: { right: { style: "thin" } },
                },
                "E",
                "I",
                mealDataArray.length
              );
            }
          );
          mealDataArray.push([""]);
        });
        mealDataArray.push([""]);
      }

      let retry = false;
      let attempt = 0;
      let currentSheetTitle = currentMeal.title.substring(0, 31);
      do {
        if (usedSheetTitles.includes(currentSheetTitle)) {
          retry = true;
          attempt++;
          currentSheetTitle =
            currentSheetTitle.substring(0, 27) + " - " + attempt;
          // console.log("usedSheetTitles.change:", currentSheetTitle);
        } else {
          // console.log("usedSheetTitles.save:", currentSheetTitle);
          usedSheetTitles.push(currentSheetTitle);
          retry = false;
          attempt = 0;
        }
      } while (retry);
      // console.log("SheetTitles.Debug", usedSheetTitles);

      sheets.push({
        title: currentSheetTitle,
        data: mealDataArray,
        styles: styles,
        merges: merges,
      });
    });
  });

  const dateObj = new Date(selectedCookDay);
  const dateString: String = dateObj.getDate() + "." + (dateObj.getMonth() + 1);

  // console.log("TK.sheets", sheets);

  xlsxGenerateStyled(`Výroba ${dateString} - Technické karty.xlsx`, sheets);
};
