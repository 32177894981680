import { useState } from "react";

export const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function modalToggle() {
    setModalIsOpen(!modalIsOpen);
  }

  return {
    modalIsOpen,
    modalToggle,
  };
};

export default useModal;
